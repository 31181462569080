import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { FormBuilder, FormControl, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.css']
})
export class Header1Component implements OnInit, OnDestroy {
  signup:String="signupform"
  loginForm: FormGroup;
  signupForm: FormGroup;
  otpForm: FormGroup;
  isValidNumber: any;
  myCode: any = '+91';
  loginMessage1: string;
  signUpMessage: any;
  cell: any;
  uuid: any;
  loginMessage: any;
  mobile: any;
  loggedIn: string;
  token: any;
  firstName: any;
  languageValue: any = "English";
  flag: boolean = false;
  timer: any = 60
  x: any;
  url: string;
  cartArr: any=[];
  cartLength: any;
  longitude: string;

  constructor(public service: DataserviceService, public spinner: NgxSpinnerService, 
    private router: Router, private formBuilder: FormBuilder) {
  //-----------TO CHECK LOCALSTORAGE RESENT NUMBER -------------//
 
  
    this.url = window.location.href.split('/')[3];
    console.log('url',this.url );
    this.token = localStorage.getItem('token')
    this.service.token.subscribe((res)=>{
      this.token = res
    })

    this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')): [];
    this.service.cartLength.subscribe((res)=>{
     this.cartLength = res
    })
}

   
  ngOnInit() {
      this.service.profileData.subscribe((res)=>{
        this.service.location = res;
      });
    this.longitude =   localStorage.getItem('longitude')?  localStorage.getItem('longitude') : '';
     this.service.location = localStorage.getItem('address');
     this.service.profile1.subscribe((res)=>{
      this.firstName = res;      
    })
    this.checkToken();
    this.logedIn();
    this.signUp();
    this.otp();
    this.checkCookie();
  }

  sinupform(){
    this.signupPage()
  }
 
  ngOnDestroy() {
    clearInterval(this.x)
  }

  resendOtp() {
    let url = `customer/resend-otp/${this.uuid}`
    this.service.getApi(url).subscribe((res: any) => {
      if (res.status == 200) {
        this.resendTimer();
      }
    }, (err) => {
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }
    })

  }

  resendTimer() {
    this.timer = 60;
    this.x = setInterval(() => {
      this.timer = this.timer - 1;
      if (this.timer == 0) {
        clearInterval(this.x);
      }
    }, 1000);
  }

  logedIn() {
    this.loginForm = this.formBuilder.group({
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{9,9}$/)]))
    })
  }

  signUp() {
    this.signupForm = this.formBuilder.group({
      'phoneNo': new FormControl('', Validators.compose([Validators.required])),
      "name": new FormControl("", [Validators.required,Validators.pattern('[a-zA-Z]*')]),
      "email": new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      "password": new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)])),
      "iagreeTerms": ['', Validators.required],
      "iagreePrivacy": ['', Validators.required],

    })
  }

  otp() {
    this.otpForm = this.formBuilder.group({
      "phnumber": new FormControl(''),
      'otp': new FormControl('', Validators.compose([Validators.required])),
    })
  }

  signupPage() {
    $("#signupModal").modal({  keyboard: false })
    this.cellCheckCountry();
  }

  closeModalSignup() {
    $("#signupModal").modal('hide')
    this.signupForm.reset();
  }

  loginModalOpen() {
    $("#loginModal").modal({ backdrop: 'static', keyboard: false })
    this.phoneCheckCountry();
  }

  loginModalClose() {
    $("#loginModal").modal('hide')
    this.loginForm.reset();
  }

  phoneCheckCountry() {
    $("#phoneNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  toCheckSpaceChar() {
    this.isValidNumber = $('#phoneNum').intlTelInput('isValidNumber');
    const countryData = $('#phoneNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }

  cellCheckCountry() {
    $("#cellNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  CheckSpaceChar() {
    this.isValidNumber = $('#cellNum').intlTelInput('isValidNumber');
    const countryData = $('#cellNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }

  signUpModal() {
    
    if(!navigator.onLine){
      this.service.toastErr('You are offline');
      return
    }
    let full_name = this.signupForm.value.name;
    let arr = full_name.split(' ')
    let f_name = arr[0]
    arr.splice(0, 1)
    let l_name = arr.join(' ')
    var data1 = {
      "first_name": f_name,
      "mobile": this.signupForm.value.phoneNo,
      "email": this.signupForm.value.email,
      "password": this.signupForm.value.password,
      "country_code": this.myCode,
      "last_name": l_name,
    }
    this.spinner.show();
    this.service.postApi('customer/signup', data1).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status == 201) {
        this.signUpMessage = res.body.message
        $('#signupModal').modal('hide');
        this.resendTimer();
        $('#otpModal').modal({ backdrop: 'static', keyboard: false })
        this.cell = res.body.mobile
        this.uuid = res.body.uuid;
        this.otpForm.patchValue({
          phnumber: this.cell
        })
      }
    }, error => {
      this.spinner.hide();
      if (error.status == 401 || error.status == 403 || error.status == 400) {
        this.service.toastErr(error.error.detail || error.error.message)
      }
    })
  }



  login() {
    if(!navigator.onLine){
      this.service.toastErr('You are offline!');
      return;
    }
    var data = {
      "country_code": this.myCode,
      "mobile": this.loginForm.value.phone,
    }
    // this.x =setInterval(()=>
    // this.service.showSpinner(),5000)
    // clearInterval(this.x);
    this.service.showSpinner()
    this.service.postApi('customer/login', data).subscribe(res=>{
      this.spinner.hide();
      if (res.status == 200) {
        this.loginMessage = res.body.message
        this.loginForm.reset()
        this.uuid = res.body.uuid
        localStorage.setItem('uuid', this.uuid) ;
        localStorage.setItem('is_signup',res.body.is_signup)
        $('#loginModal').modal('hide');
        this.resendTimer();
        $('#otpModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.otpForm.patchValue({
          phnumber: this.mobile
        })
      }
      else if (res.status == 201) {
        this.loginMessage1 = res.body.message
        this.loginForm.reset()
        $('#loginModal').modal('hide')
        $('#signupModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.signupForm.patchValue({
          phoneNo: this.mobile
        })
      }else if(res.ststus == 401){
        this.service.showErrToast(res.body.detail)
      }
    },err=>{
      this.spinner.hide()
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }
    })

  }

//------------------------verify otp----------------------//
  verify(){
    var data = {
      "code": this.otpForm.value.otp
    }
    this.spinner.show();
    this.service.postApi(`customer/otp-verification/${this.uuid}`, data).subscribe(res=>{
      if (res.status == 200) {
        $('#otpModal').modal('hide')
        console.log(res)
        this.service.showSuccess(res.body.message)
        localStorage.setItem('token', res.body.token)
        localStorage.setItem('firstname', res.body.first_name)
        localStorage.setItem('user_id',res.body.user_id)
        localStorage.setItem('support_id',res.body.support_id)

        this.checkToken();
        if (localStorage.getItem('latitude') && localStorage.getItem('logitude')) {
          this.router.navigate['/nearyou']
          this.service.showSuccess(res.body.message)
        } else {
          this.router.navigate['/home']

        }
      }
    },err=>{
      this.spinner.hide()
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }
    })

  }

  homenavigate() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/nearyou'])
    }
    else {
      localStorage.clear()
      this.router.navigate(['/home']);
    }
  }

  changeLanguage(event) {
    this.languageValue = event;
    if (this.languageValue == 'English') {
      this.flag = false;
      this.service.translate.use('en');
      this.service.language.next('en')
    } else {
      this.flag = true;
      this.service.translate.use('th');
      this.service.language.next('th')

    }
  }

  menu(event){
    $('#navbarSupportedContent').toggle('show')
  }

  checkToken() {
    this.spinner.show();
    this.token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    this.spinner.hide();
   
    if (this.token) {
      this.firstName = localStorage.getItem('firstname');
     
    }
  }

  closeTimer() {
    clearInterval(this.x)
  }
  logOut() {
    
    let resetNumber,cookie_value 
    if(localStorage.getItem('resetNumber')) {
      resetNumber = localStorage.getItem('resetNumber')
    }

    if(localStorage.getItem('cookie')) {
      cookie_value = localStorage.getItem('cookie');
    }
    localStorage.clear();
    localStorage.removeItem('restaurantDetail');
    localStorage.removeItem('myCart');
    if(resetNumber == "true") {
      localStorage.setItem('resetNumber',resetNumber)
    }
    if(cookie_value) {
      localStorage.setItem('cookie',cookie_value)
    }
    this.token = null
    this.router.navigate([''])
  }
  // logout() {
  //   // console.log('logout')
  //   // this.service.logOut()
  //   let cookie = localStorage.getItem('cookie')
  //   localStorage.clear();
  //   localStorage.removeItem('myCart')
  //   localStorage.setItem('cookie', cookie)
  //   this.checkToken();
  //   this.firstName = ''
  //   this.router.navigateByUrl('')
  // }

  checkCookie() {
    this.service.showSpinner()
    if (!localStorage.getItem('cookie')) {
      $('#cookieModal').modal({ backdrop: 'static', keyboard: false })

    }
    setTimeout(() => {
      this.service.hideSpinner()
    }, 4000);
    console.log('pathname',window.location.pathname);
    if (window.location.pathname == '/nearyou' || window.location.pathname == '/restaurant'){
      setTimeout(() => {
        this.service.hideSpinner()
      }, 10000);
    }
  }


  navigateToCart(){
    if(localStorage.getItem('myCart')) {
      let myCart = JSON.parse(localStorage.getItem('myCart'))
      if(myCart.length == 0) {
        this.router.navigate(['/cart']);
        return;
      }
      this.router.navigate(['/account1'])
    }else{
      this.router.navigate(['/cart'])
    }
  }



}
