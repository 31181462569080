import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { environment } from 'src/environments/environment';


declare var google:any;
@Component({
  selector: 'app-staticcontent',
  templateUrl: './staticcontent.component.html',
  styleUrls: ['./staticcontent.component.css']
})
export class StaticcontentComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  zoom: number = 7;
  lat: number;
  lng: number;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  PlaceholderTo:string = 'To';
  PlaceholderFrom:string = 'From';
  type: any;
  content: any;
  contactForm: FormGroup;
  partnerDetails: FormGroup;
  partnerBanner_title: any;
  partnerBanner_desc: any;
  partnerWithUs_title: any;
  partnerWithUs_desc: any;
  rideBanner_title: any;
  rideBanner_desc: any;
  rideWithUs_desc1: any 
  rideWithUs_desc2: any;
  rideWithUs_desc3: any;
  rideWithUs_desc: any;
  faqItems : any =[];
  refundItem: any;
  TermItem: any;
  PrivacyContents: any;
  AboutContext: any;
  BlogItem : any = [];
  NewsItem: any =[];
  newsForm : FormGroup;
  pos: { lat: number; lng: number; };
  CountryCodeItems: any =[];
  language: string = "en";
  blogSlug: any;
  ViewBlogItem: any;
  myurl: string;
  token: any;
  blogs: any= [];
  pageNumber:number=1
  pages: number = 1;
  page: number = 10;
  rest: any;
  constructor(private activatedRoute: ActivatedRoute,private router : Router,
     public service: DataserviceService, private formBuilder: FormBuilder,
    public spinner: NgxSpinnerService,
    private translate: TranslateService) {
      let url1 = window.location.pathname.split('/');
     this.myurl=url1[2];
     }

    

  ngOnInit() {
    this.token = localStorage.getItem('token') ||  ''
    
    
   
    this.service.BlockFuture();
    this.getContent();
    this.newsFormValidation();
    this.partnerForm();
    this.initMap()
    this.activatedRoute.paramMap.subscribe(params => {
      this.content = params.get('type');
      console.log("Type=-=-=-=->", this.content);
      this.contactUs();
      this.partnerBanner();
      this.partnerWithUs();
      this.rideBanner();
      this.whyFoodDelivery();
      this.getTermCondition();
      this.getFaq();
      this.refundAndCancellation();
      this.PrivacyPolicy();
      this.getAboutUs();
      this.getBlogs();
      this.getNews();
      this.CountryCode();
    });
    this.service.language.subscribe(res=>{
      this.language = res;
      console.log( ' this.language',this.language);
      if(res != ''){
        if (this.content == 'news'){
          this.getNews()
        }
        else if (this.content == 'blogs'){
          this.getBlogs();
        }
        else if (this.content == 'aboutus'){
          this.getAboutUs();
        }
        else if (this.content == 'faqs'){
        this.getFaq();
        }
        else if (this.content == 'partner'){
          this.partnerBanner();
          this.partnerWithUs();
          }
          else if (this.content == 'ride'){
            this.rideBanner();
            this.whyFoodDelivery();
            }
            else if (this.content == 'termsconditions'){
              this.getTermCondition();
            }
            else if (this.content == 'refund'){
              this.refundAndCancellation();
            }
            else if (this.content == 'privacypolicy'){
              this.PrivacyPolicy();
            }
      }
      else{
        this.language = "en";
      }
    })
  }

  getContent() {
    this.activatedRoute.params.subscribe((res) => {
      this.type = res.type;
      window.scrollTo(0, 0);
    })
  }



  // ------------------------ news form validation ------------------------------------------------------//
  newsFormValidation(){
    this.newsForm =  new FormGroup({
      "fromDate": new FormControl(''),
      "endDate":  new FormControl('')
    })
  }

  //-------------------- Search by date api integration ------------------------------------------------------------//


  SearchBydate() {
     let channel = `content/news?uesr=customer&created_at_after
     =${this.newsForm.value.fromDate}&created_at_before=${this.newsForm.value.endDate}`;
     if (this.language == 'th' ){
      channel = `content/news?uesr=customer&lang=th&created_at_after
      =${this.newsForm.value.fromDate}&created_at_before=${this.newsForm.value.endDate}`;
     }
   this.spinner.show()
  this.service.getApi(channel).subscribe((res:any)=>{
    console.log('SearchBydate',res);
     this.spinner.hide()
     if(res.status==200){
      this.NewsItem = res.body.results;
      this.newsForm.reset()
      }
  }, error => {
    console.log('error', error)
    if (error.status == 401 || error.status == 403) {
      this.service.toastErr(error.error.detail);
      this.spinner.hide()
    }
  })

  }

  resetNews(){
    this.newsForm.reset();
    this.getNews();
  }


 //------------------------- View News---------------------------------------------------------------------//

 viewNews(slug){
  this.router.navigate(['/viewnews']).then(()=>
  this.service.emmiter.emit(slug)
)
 } 
 // .....................................................contact Us.......................................//
 contactUs() {
  this.contactForm = this.formBuilder.group({
    customer_name: ['', [Validators.required,Validators.maxLength(30),Validators.pattern('^[a-zA-Z0-9]*$')]],
    customer_email: ['', Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/i)])],
    customer_pcode: ['+91', [Validators.required]],  
    customer_phonenumber: ['', [Validators.required,Validators.pattern('^[0-9]{10}$')]],
    customer_message: ['', [Validators.required,Validators.maxLength(160),Validators.minLength(10)]]
  });
}

saveContactDetails() {
  var data = {
    'name': this.contactForm.value.customer_name,
    'email': this.contactForm.value.customer_email,
    'country_code': this.contactForm.value.customer_pcode,
    'mobile': this.contactForm.value.customer_phonenumber,
    'message': this.contactForm.value.customer_message,
    "country":this.contactForm.value.customer_pcode,
    "type":"Contact us"
    
  };

  this.service.postApi('api/contact-us', data).subscribe((res: any) => {
   if(res.status == 201){
     console.log(res)
    this.service.showSuccess(res.body.message)     
   }
    
    this.contactForm.reset();
  })
}
AlphabetOnly(event){
  let pattAlpha = /^([a-zA-Z ])*$/;
  let resultAlpha = pattAlpha.test(event.key);
  return resultAlpha;
}

public errorMessages = {
  customer_name: [
    { type: 'required', message: '*Name is required.' },
    { type: 'pattern', message: 'Please enter a valid name.' },
    { type: 'maxlength', message: 'Name cant be longer than 60 characters.' }
  ],
  customer_email: [
    { type: 'required', message: '*Email is required.' },
    { type: 'pattern', message: 'Please enter a valid email address.' }
  ],
  customer_phonenumber: [
    { type: 'required', message: '*Phone number is required.' },
    { type: 'pattern', message: 'Please enter a valid phone number.' }
  ],
  customer_pcode:[{
    type: 'required', message: '*Code is Required.'
  }],
  customer_message:[
    {type: 'required', message: '*Message is Required.'},
    {type: 'maxlength', message: 'Message cant be longer than 60 characters.' },
    {type:'minlength' ,message:'Message cant be lesser than 10 characters'}
  ]
};

get customer_name() {
  return this.contactForm.get("customer_name");
}
get customer_email() {
  return this.contactForm.get('customer_email');
}
get customer_phonenumber() {
  return this.contactForm.get('customer_phonenumber');
}
get customer_pcode() {
  return this.contactForm.get('customer_pcode');
}
get customer_message() {
  return this.contactForm.get('customer_message');
}

 CountryCode(){
 if ((this.content == 'contactus') || (this.content == 'partner') ) {
let channel = "api/country-list";
this.service.getApi(channel).subscribe((res:any)=>{
  this.CountryCodeItems = res.body;
  console.log('Code',res)
})
 }
}
  //................................................... partner with us................................//
  partnerForm() {
    this.partnerDetails = this.formBuilder.group({
      name: ['', [Validators.required,Validators.pattern('^[a-zA-Z0-9]*$')]],
      country: ['India', Validators.required],
      pcode: ['+91', Validators.required],
      phonenumber:  ['', [Validators.required,Validators.pattern('^[0-9]{10}$')]],
      email: ['', Validators.compose([Validators.required, Validators.maxLength(60), Validators.pattern(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,3}|[0-9]{1,3})(\]?)$/i)])],
      message: ['', [Validators.required,Validators.maxLength(160),Validators.minLength(10)]]
    });
  }
  savePartnerDetails() {
    let partner_data = {
      'name': this.partnerDetails.value.name,
      'country': this.partnerDetails.value.country,
      // "country":"675",
      'country_code': this.partnerDetails.value.pcode,
      'mobile': this.partnerDetails.value.phonenumber,
      'email': this.partnerDetails.value.email,
      'message': this.partnerDetails.value.message,
      'type': "Partner with us"


    };

    this.service.postApi('api/contact-us', partner_data).subscribe((res: any) => {
      if(res.status == 201){
        console.log(res)
       this.service.showSuccess(res.body.message)     
      }
       
       this.partnerDetails.reset();
     })
   }

  public PartnererrorMessages = {
    name: [
      { type: 'required', message: '*Name is required.' },
      { type: 'pattern', message: 'Please enter a valid name.' },
      { type: 'maxlength', message: 'Name cant be longer than 60 characters.' }
    ],
    email: [
      { type: 'required', message: '*Email is required.' },
      { type: 'pattern', message: 'Please enter a valid email address.' }
    ],
    phonenumber: [
      { type: 'required', message: '*Phone number is required.' },
      { type: 'pattern', message: 'Please enter a valid phone number.' }
    ],
    
    message:[
      {type: 'required', message: '*Message is Required.'},
      {type: 'maxlength',message: 'Message cant be longer than 60 characters.' },
      {type:'minlength' ,message:'Message cant be lesser than 10 characters'}
      
    ]
  };

  get name() {
    return this.partnerDetails.get("name");
  }
  get email() {
    return this.partnerDetails.get('email');
  }
  get phonenumber() {
    return this.partnerDetails.get('phonenumber');
  }
  

  partnerBanner() {
    if (this.content == 'partner'){

    
    let url = 'master/banner-content/partner';
    if (this.language == 'th'){
    url ='master/banner-content/partner/th'
    }
    this.service.getApi(url).subscribe((res: any) => {
      console.log('Banner',res)
      if (res.status == 200) {

        this.partnerBanner_title = res.body.title;
        
        this.partnerBanner_desc = res.body.description
        
      }

    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
    })
  }
  }
  // @HostListener('window:click', ['$event']) 
  // scrollFunc(event) {
  //   console.log('event',event);
  //   let url1 = window.location.pathname.split('/');
  //   this.myurl=url1[2];
  //   if (this.content == 'partner'){
  //     window.scroll(750,1264);

  //   }
  // }
  scrollFunc(){
    document.getElementById('middle').scrollIntoView()
  }
  

  partnerWithUs() {
    if (this.content == 'partner'){

    let url = 'master/partner-with-us';
    if (this.language == 'th'){
     url = 'master/partner-with-us/th'
    }
    
    this.service.getApi(url).subscribe((res: any) => {
      
      if (res.status == 200) {

        this.partnerWithUs_title = res.body.title;
        
        this.partnerWithUs_desc = res.body.description;
      
      }

    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
    })
  }
  }

  //............................................. Ride with us.......................................//
  rideBanner() {
    if (this.content == 'ride'){

    let url = 'master/banner-content/ride';
    if (this.language == 'th'){
     url = 'master/banner-content/ride/th'
    }
    this.service.getApi(url).subscribe((res: any) => {
      
      if (res.status == 200) {

        this.rideBanner_title = res.body.title;
        this.rideBanner_desc = res.body.description;
      }

    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
    })
  }
  }
  whyFoodDelivery() {
    if (this.content == 'ride'){

    let url = 'master/why-food';
    if (this.language == 'th'){
      url ='master/why-food/th'
    }
    this.spinner.show()
    this.service.getApi(url).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status == 200) {
        console.log(res)

        this.rideWithUs_desc1 = res.body
      }

    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
    })
  }
  }
  createUrl(url) {
    let tempUrl = '';
    if (this.translate.currentLang == "th") {
      tempUrl = `${url}/${this.translate.currentLang}`;
      url = tempUrl;
    } else {
      tempUrl = url;
    }
    return tempUrl;
  }

 
//--------------------------------------------Terms&Conditions----------------------
getTermCondition(){
  if (this.content == 'termsconditions'){
  let channel = 'content/terms-and-condition?user=customer&lang=en';
  if(this.language == 'th'){
     channel = 'content/terms-and-condition?user=customer&lang=th';

  }
  this.service.showSpinner()
this.service.getApi(channel).subscribe((res:any)=>{
  if(res.status==200){
    this.TermItem = res.body.content;
    setTimeout(() => {
      this.service.hideSpinner();
    }, 2000);
  }
}, error => {
  console.log('error', error)
  if (error.status == 401 || error.status == 403) {
    this.service.toastErr(error.error.detail);
    this.spinner.hide()
  }
})
}
}
getFaq(){
  if (this.content == 'faqs'){
    this.service.showSpinner();
    var url ;
    if (this.language == 'en'){
       url = 'content/faq?user=customer&lang=en';
    }
    else if (this.language == 'th'){
      url = 'content/faq?user=customer&lang=th';
    }
    // let url = 'content/faq?user=customer&lang=en/th';
    this.service.getApi(url).subscribe((res:any)=>{
      if(res.status==200){
  
        this.faqItems = res.body.results;
        setTimeout(() => {
          this.service.hideSpinner();
        }, 2000);
      }
  
    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
      })
  }
  
}
refundAndCancellation(){
  if (this.content == 'refund'){

  let channel = "content/refund-cancellation"
  if (this.language == 'en'){
    channel = "content/refund-cancellation?lang=en"
  }
  else if (this.language == 'th'){
    channel = "content/refund-cancellation?lang=th"
  }
  this.service.showSpinner()
  this.service.getApi(channel).subscribe((res:any)=>{
    if(res.status==200){
     this.refundItem = res.body.description;
     setTimeout(() => {
       this.service.hideSpinner()
     }, 2000);
    }
  }, error => {
    console.log('error', error)
    if (error.status == 401 || error.status == 403) {
      this.service.toastErr(error.error.detail);
      this.spinner.hide()
    }
  })
}
}

PrivacyPolicy(){
  if (this.content == 'privacypolicy'){
  this.service.showSpinner();
  let channel ;
  if (this.language == 'en'){
    channel = "content/privacy-policy?lang=en"
  }
  else if (this.language == 'th'){
    channel = "content/privacy-policy?lang=th"

  }
  this.service.getApi(channel).subscribe((res:any)=>{

    if(res.status==200){
      this.PrivacyContents = res.body.content;
      setTimeout(() => {
        this.service.hideSpinner();
      }, 2000);
    }
  }, error => {
    console.log('error', error)
    if (error.status == 401 || error.status == 403) {
      this.service.toastErr(error.error.detail);
      this.spinner.hide()
    }
  })
}
}
getAboutUs(){
  
  if ( this.content == 'aboutus'){
    let channel = `content/about-us?lang=${this.language}`;
    console.log('language',this.language);
    
   
    this.service.getApi(channel).subscribe((res:any)=>{
       this.spinner.show()
      if(res.status==200){
        this.AboutContext = res.body.description;
        setTimeout(() => {
          this.spinner.hide()
        }, 2000);
        }
        else{
          this.spinner.hide()
        }
    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
        })
  }

}
getBlogs(){
  if (this.content == 'blogs'){
    let channel = "content/blogs";
    if (this.language == 'th'){
      channel = "content/blogs?lang=th"
    }
  
    this.service.getApi(channel).subscribe((res:any)=>{
      if(res.status==200){
        this.BlogItem = res.body.results[0];
        this.blogs = res.body.results
        this.blogSlug = res.body.results[0].slug;
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
      }
      else{
        this.spinner.hide()
      }
    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
        this.spinner.hide()
      }
      })
  }
  
}

viewBlog(slug){
  if (slug){
    this.blogSlug = slug
  }
  let url = `content/blogs/${this.blogSlug}`;
  if (this.language == 'th'){
    url = `content/blogs/${this.blogSlug}/th`
  }
  
  this.service.getApi(url).subscribe((res:any)=>{
    this.spinner.show()
    if(res.status==200){
      this.spinner.hide()
      this.ViewBlogItem = res.body.description;
      this.BlogItem =  res.body
    }
    else{
      this.spinner.hide()
    }
  }, error => {
    console.log('error', error)
    if (error.status == 401 || error.status == 403) {
      this.service.toastErr(error.error.detail);
      this.spinner.hide()
    }
  })
}

doSmth(reachedTarget: boolean): void {
        if (reachedTarget) {
            console.log('Yeah, we reached our destination');
        } else {
            console.log('Ohoh, something interrupted us');
        }
    }

getNews(){
  if (this.content == 'news'){
    let channel = "content/news?uesr=customer";
    if (this.language == 'th'){
      channel =  "content/news?uesr=customer&lang=th";
    }
    this.spinner.show();
    this.service.getApi(channel).subscribe((res:any)=>{
      if (res.status == 200){
        setTimeout(() => {
          this.spinner.hide();
        }, 1000);
        this.NewsItem = res.body.results;
        this.NewsItem.forEach((res)=>{
          var regex = /(<([^>]+)>)/ig; 
          res.description =  res.description.replace(regex,"")         
          if (res.description.length > 300){
            res.description=res.description.slice(0,300)+'...'
          }
        })
      }
      else{
        this.spinner.hide();
        this.NewsItem = res.body.results;
      }
   
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
      }
      })
  }
  
  
  }
  
  ViewNewsDetails(id){
  
  this.router.navigateByUrl(`viewnews/${id}`)
  }


initMap() {
  
  this.infoWindow = new google.maps.InfoWindow();

  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      position => {
       this.pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        this.service.getLocation(environment.mapUrl + `${JSON.stringify(this.pos.lat)},${JSON.stringify(this.pos.lng)}`).subscribe(res => {
         
          console.log(res.status)
          if (res.status == 'OK') {
            this.service.location = res.results[1].formatted_address;    
          }})
        this.lat =  this.pos.lat ;
        this.lng = this.pos.lng ;
        console.log('position', this.pos);
        
        this.map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center:  this.pos,
          zoom: 6,
        });
        this.infoWindow.setPosition( this.pos);
        this.infoWindow.setContent("Location found.");
        this.infoWindow.open(this.map);
        this.map.setCenter( this.pos);
        var geocoder = new google.maps.Geocoder;
        const infowindow = new google.maps.InfoWindow;
        console.log('map', this.map, 'location', infowindow , 'geocoder' ,geocoder);
      },
      () => {
        this.handleLocationError(true, this.infoWindow, this.map.getCenter());
      }
    );
  } else {
    // Browser doesn't support Geolocation
    this.handleLocationError(false, this.infoWindow, this.map.getCenter());
  }
}


handleLocationError (
  browserHasGeolocation: boolean,
  infoWindow: google.maps.InfoWindow,
  pos: google.maps.LatLng
) {
  infoWindow.setPosition(pos);
  infoWindow.setContent(
    browserHasGeolocation
      ? "Error: The Geolocation service failed."
      : "Error: Your browser doesn't support geolocation."
  );
  infoWindow.open(this.map);
}

  // ------------------------pagination -------------------------//
  pagination(page){
    this.pageNumber=page;
    this.getBlogs() 
  }
}
