import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataserviceService } from '../../Service/dataservice.service';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment'
import { Header1Component } from '../header1/header1.component';
import { TranslateService } from '@ngx-translate/core';


declare var $: any;
declare const google: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  searchLoc: FormGroup
  lat: Number;
  lng: Number;
  options: any[];
  showCookie: boolean = false;
  bannerData: any;
  HowItworks: any = [];
  restaurentInyourhand: any;
  language: any;
  getDoingBusinessData: any = [];
  submitted :boolean = false;
  deliveryType:any;

  //-------------------- Manage address from dropdown --------------------------------------------
  public handleAddressChange(address: Address) {
    this.options = []
    localStorage.setItem('address',address.formatted_address)
    this.lat = address.geometry.location.lng()
    this.lng = address.geometry.location.lat()
    console.log('lat ',this.lat,'long',this.lng);

    localStorage.setItem('latitude', JSON.stringify(this.lng))
    localStorage.setItem('longitude', JSON.stringify(this.lat))
    this.options.push(address.formatted_address);
  }
  constructor(public service: DataserviceService, private router: Router, public spinner: NgxSpinnerService, public header:Header1Component ,public translate: TranslateService,) {
    translate.addLangs(['en', 'fr']);
    translate.setDefaultLang('en');
   
    if (!localStorage.getItem('cookie')) {
      this.showCookie = true
    }
  }

  ngOnInit() {
    this.checkAutoLogin()
    window.scrollTo(10,10);
    this.searchLoc = new FormGroup({
      'searchBar': new FormControl('', Validators.required)
    });
    this.service.language.subscribe(res=>{
      this.language = res;
      console.log( ' this.language',this.language);
      if(res != ''){
        this.getBannerTranslateApi();
        this.getHowItworksTranslateApi();
        this.getRestaurentInyourHandTranslateApi();
      }
      
    });
    this.searchLoc.valueChanges.subscribe((res)=>{
      console.log('form value',res.searchBar);
      if(res.searchBar !='' && res.searchBar != undefined){
        this.submitted = true;
      }
      
    })
    this.getBannerApi();
    this.getHowItworks();
    this.getRestaurentInyourHandApi();
    this.getDoingBusinessApi() ;
   
  }

  checkAutoLogin() {
    if(localStorage.getItem('resetNumber') && !localStorage.getItem('token')){
      this.header.loginModalOpen();
      localStorage.removeItem('resetNumber')
    }
  }

  get f() { return this.searchLoc.controls; }

  onSubmit() {
    // stop here if form is invalid
    this.submitted = true;
    if (this.searchLoc.invalid) {
      return;
    }
    this.router.navigate(['/nearyou'])
  }

  pickUpApi(){
    this.deliveryType = 'is_take_away=true';
    localStorage.setItem('deliveryType',this.deliveryType)
      this.router.navigate(['/nearyou'], {queryParams : {value :  this.deliveryType}})
  }

  delivery(){
    this.deliveryType = 'delivery=true';
    localStorage.setItem('deliveryType',this.deliveryType)
  }

  //----------------------- TO GET THE CURRENT LOCATION -----------------------------------------------------
  locate() {
    if (!navigator.onLine) {
      this.service.toastErr('You are offline!')
      return;
    }

    if (!navigator.geolocation) {
      this.service.toastErr('Geolocation is not supported by this browser.');
      return;
    }
    // this.spinner.show()
    navigator.geolocation.getCurrentPosition(position => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
      
      localStorage.setItem('latitude', JSON.stringify(this.lat))
      localStorage.setItem('longitude', JSON.stringify(this.lng))
      this.service.getLocation(environment.mapUrl + `${localStorage.getItem('latitude')},${localStorage.getItem('longitude')}`).subscribe(res => {
        this.spinner.hide()
        console.log(res.status)
        if (res.status == 'OK') {
          localStorage.setItem('address', res.results[1].formatted_address)
          this.service.location = res.results[1].formatted_address;
          this.spinner.hide();
         
            this.router.navigate(['nearyou'])
        }
      }, err => {
        this.spinner.hide();
        console.log(err)
      })
    })
  }


  setCookieValue(cookies) {
    this.showCookie = false
    localStorage.setItem('cookie', cookies)
  }


  // Get banner api integration

  getBannerApi() {
    this.spinner.show();
    this.service.getApi('master/banner-content/website').subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
        this.bannerData = res.body;
        console.log(' this.bannerData', this.bannerData)

      }
    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }


  // Get banner translate api integration

  getBannerTranslateApi() {
    this.spinner.show();
    this.service.getApi('master/banner-content/website/'+this.language).subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
        this.bannerData = res.body;
        console.log('this.bannerData description', this.bannerData)
        console.log('this.bannerData ', res.body)

      }
    }, error => {
      this.spinner.hide();

      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

  // Get HOW IT WORKS api integration

  getHowItworks() {
    this.spinner.show();
    this.service.getApi('master/how-it-works').subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
         this.HowItworks = res.body;
         console.log(' this.bannerData', this.HowItworks)
      }
    }, error => {
      this.spinner.hide();
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

   // Get banner translate api integration

   getHowItworksTranslateApi() {
    this.spinner.show();
    this.service.getApi('master/how-it-works/'+this.language).subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
        this.HowItworks = res.body;
        console.log(' this.bannerData', this.bannerData)
      }
    }, error => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

  // Get Restaurent in your hand api integration

  getRestaurentInyourHandApi() {
    this.spinner.show();
    this.service.getApi('master/restaurant-in-your-hand').subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
         this.restaurentInyourhand= res.body;
         console.log(' this.restaurentInyourhand', this.restaurentInyourhand)
      }
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

  // get Restaurent In your Hand Translate Api Integration

  getRestaurentInyourHandTranslateApi() {
    this.spinner.show();
    this.service.getApi('master/restaurant-in-your-hand/'+this.language).subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
         this.restaurentInyourhand= res.body;
         console.log(' this.bannerData', this.restaurentInyourhand)
      }
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

    // get doing business Api Integration

  getDoingBusinessApi() {
    this.spinner.show();
    this.service.getApi('master/doing-buisness').subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
         this.getDoingBusinessData= res.body;
         console.log(' this.bannerData', this.getDoingBusinessData)
      }
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

  // get doing business Api for language change Integration

  getDoingBusinessTranslateApi() {
    this.spinner.show();
    this.service.getApi('master/doing-buisness/'+this.language).subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
         this.getDoingBusinessData= res.body;
         console.log(' this.bannerData', this.getDoingBusinessData)
      }
    }, error => {
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }
  

}










