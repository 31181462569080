import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse, HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Header1Component } from '../pages/header1/header1.component';
declare var $ : any ;

@Injectable({
  providedIn: 'root'
})
export class DataserviceService  {
 public profileObj: any={};
 month: any;
  day: any;
  daily: string;
  year: number;
  dtToday: Date;
  maxDate: string;
  chatArr: any=[];
  profileObj1: any={};
  profileObj2: any = {};
  trackSocket: WebSocket;
  trackOrderObj: any = {};
  origin: { lat: any; lng: any; };
  destination: { lat: any; lng: any; };
  track_status: any='Order Placed';
  discountData: any ={};
  modified: HttpRequest<any>;
  orderDetailsObj: any = {};
  room_id: any;
  spinner() {
    throw new Error('Method not implemented.');
  }
  location: any;
  public cartLength = new BehaviorSubject(``);
  public profileData = new BehaviorSubject(this.profileObj);
  public language = new BehaviorSubject (``);
  public profile1 = new BehaviorSubject(``);
  public token = new BehaviorSubject(``);
  data =  this.profileData.asObservable();
  public locationUpdate = new BehaviorSubject(this.profileObj1)
  data1 =  this.locationUpdate.asObservable();
  public profile = new BehaviorSubject(this.profileObj2)
  data2 =  this.locationUpdate.asObservable();
  public discount = new BehaviorSubject(this.discountData)
  emmiter  = new EventEmitter();
  // baseUrl = "http://172.16.2.15:8000/v1/" //local
  // http://182.74.213.163:8006/v1
  //  baseUrl="http://182.74.213.163:8006/v1/"
  // baseUrl = "https://fooddeliverypython.mobiloitte.com/v1/" // staging
  // baseUrl = 'http://172.16.6.67:8000/v1/'  // local

  baseUrl ='https://fooddeliverypython.mobiloitte.io/v1/'



  loginSub = new BehaviorSubject(``);
  loginObs = this.loginSub.asObservable();
  socket: WebSocket;
  socketStatus: boolean = false;
  httpOptions:any = {observe:'response'}
  constructor(public translate: TranslateService, private http: HttpClient,public Spinner: NgxSpinnerService,
     private toastr: ToastrService, private router: Router) {

  }

  changeLoginSub(msg) {
    this.loginSub.next(localStorage.nexttoken);
  }

  //------------------------------------Manage Rest API-----------------------------------------
  
  getLocation(url:any):Observable<any> {
    return this.http.get(url)
  }

  getMockLocation():Observable<any> {
    return this.http.get('./assets/loc.json')
  }

  getApi(url):Observable<any> {
    if(localStorage.getItem('token')) {
      this.httpOptions['headers'] = new HttpHeaders({Authorization:`JWT ${localStorage.getItem('token')}`})
    }

    
    return this.http.get(this.baseUrl + url, this.httpOptions)
  }

  postApi(url,data):Observable<any> {
    if(localStorage.getItem('token')) {
      this.httpOptions['headers']= new HttpHeaders({Authorization:`JWT ${localStorage.getItem('token')}`})
    }
    console.log(this.http.post(this.baseUrl + url,data,this.httpOptions));
    console.log(this.baseUrl + url)
    
    return this.http.post(this.baseUrl + url,data,this.httpOptions)
  }

  putApi(url,data):Observable<any> {
    if(localStorage.getItem('token')) {
      this.httpOptions['headers']= new HttpHeaders({Authorization:`JWT ${localStorage.getItem('token')}`})
    }
    return this.http.put(this.baseUrl + url,data,this.httpOptions)
  }

  deleteApi(url):Observable<any> {
    if(localStorage.getItem('token')) {
      this.httpOptions['headers']= new HttpHeaders({Authorization:`JWT ${localStorage.getItem('token')}`})
    }
    return this.http.delete(this.baseUrl + url,this.httpOptions)
  }


  // ------------ Toaster for Messages----------------------------------------------------------------
  showSuccess(msg) {
    this.toastr.success(msg)
  }
  toastErr(msg) {
    this.toastr.error(msg)
  }

  /** to check space */
  toCheckSpace(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 32) {
      evt.preventDefault()
    } else {
      return true;
    }
  }

  /** to check characters */
  toCheckSpaceChar(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode == 32) || (charCode > 64 && charCode < 91) || (charCode > 96 && charCode < 123)) {
      evt.preventDefault()
    } else {
      return true;
    }
  }

  // to check phone no
  toCheckNumber(event) {
    // const pattern = /[0-9\+\-\ ]/;
    const pattern = /[0-9]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  // ------------------------- only presss character -----------------------//
  characterOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return true;
    }
    return false;
  }

  // logout function
  logOut() {
    let resetNumber
    if(localStorage.getItem('resetNumber')) {
      resetNumber = localStorage.getItem('resetNumber')
    }
    localStorage.removeItem('restaurantDetail');
    localStorage.removeItem('myCart');
    localStorage.clear();
    if(resetNumber == "true") {
      localStorage.setItem('resetNumber',resetNumber)
    }
    this.router.navigate(['']);
  } 
  // logOut(){
  //   this.getApi('api/logout').subscribe(res=>{
  //     if(res.ststus == 200){
  //       console.log(res)
  //     }
  //   },err=>{})
  // }


  //-------------------------------- chat socket-----------------------------------------------
  connectSocket(room_req) {
    // Create WebSocket connection.
    this.socket = new WebSocket(`wss://websocketfooddelivery.mobiloitte.com/ws/support/sender/${localStorage.getItem('user_id')}`);
    //apifooddelivery.mobiloitte.com/ws/support/sender/13



    // Connection opened
    this.socket.addEventListener('open', (event) => {
      // alert('Socket Open')
      this.socket.send(JSON.stringify(room_req))
    });

    this.socket.addEventListener('close', (event) =>{
      // alert('Socket Close')
      // this.socket = new WebSocket(`wss://websocketfooddelivery.mobiloitte.com/ws/support/sender/${localStorage.getItem('user_id')}`);
    })

    // Listen for messages
    this.socket.addEventListener('message', (event)=> {
      console.log('Message from server ', event.data);
      let eventData = JSON.parse(event.data)
      if(( eventData.room_id && eventData.sender_id != localStorage.getItem('user_id'))){
        this.chatArr.push(eventData);
      }
      if(eventData.room_id && this.chatArr.length > 0){
        this.chatArr[0]['room_id'] = eventData.room_id
      }
      if(eventData.room_id){
        this.room_id = eventData.room_id
      }
    });
  }
  
  connectTrackOrderSocket(order_id) {
    this.orderDetail()
    // Create WebSocket connection.
    this.trackSocket = new WebSocket(`wss://websocketfooddelivery.mobiloitte.com/ws/track-order/${order_id}`);
    let self = this;
    // Connection opened
    this.trackSocket.addEventListener('open', (event) => {
      // alert('Socket Open')
      self.socketStatus = true;
      console.log('Track Socket Open')
    });
    
    this.trackSocket.addEventListener('close',(event)=>{
      // this.socket = new WebSocket(`wss://13.250.224.209:8003/ws/track-order/${order_id}`);
      self.socketStatus = false;
      this.socket = new WebSocket(`wss://websocketfooddelivery.mobiloitte.com/ws/track-order/${order_id}`);
      // alert('Socket Close')

    });

    // Listen for messages
    this.trackSocket.addEventListener('message', (event)=> {
      console.log('Message from server ', event.data);
      this.trackOrderObj = JSON.parse(event.data)
      this.getDirection()
      this.orderDetail()

    });

  }

  





  //
  getDirection() {
    let trackOrder = JSON.parse(localStorage.getItem('trackOrder'));
    trackOrder['driver_lat'] = this.trackOrderObj.driver_live_location.latitude
    trackOrder['driver_lng'] = this.trackOrderObj.driver_live_location.longitude;
      this.orderDetail();      
      console.log('trackOrderObj===>234', this.trackOrderObj);
      
      trackOrder['order_status'] = this.orderDetailsObj.order_status
      // trackOrder['order_status'] = this.trackOrderObj.order_status
    
    localStorage.setItem('trackOrder',JSON.stringify(trackOrder))
    this.track_status = this.trackOrderObj.order_status
    if( this.track_status == 'Order Placed' || this.track_status == 'Pending'|| this.track_status == 'Order Confirmed' || this.track_status == 'Order Processed'|| this.track_status == 'Driver Assigned'){
      this.origin = { lat: this.trackOrderObj.outlet_lat || 0.000, lng: this.trackOrderObj.outlet_long || 0.000 }; //restaurnat
    }else{
      this.origin = { lat: this.trackOrderObj['driver_live_location']['latitude'] || 0.000, lng: this.trackOrderObj['driver_live_location']['longitude'] || 0.000}; //driver
    }
    this.destination = { lat: this.trackOrderObj.user_lat || 0.000, lng: this.trackOrderObj.user_long || 0.000}; // customer
    // Location within a string
    // this.origin = 'Taipei Main Station';
    // this.destination = 'Taiwan Presidential Office';
  }

  orderDetail(){
    
    if(!localStorage.getItem('token')) {
      this.toastErr('Please login first!')
      this.router.navigate(['']);
      return;
    }
    let orderIdData = localStorage.getItem('trackOrder')? JSON.parse(localStorage.getItem('trackOrder'))  : '';
    let  orderId =orderIdData.orderId
    this.getApi(`customer/order-detail/${orderId}`).subscribe(res=>{
      if( res.status == 200){
        this.orderDetailsObj= res.body;
        let trackOrder = JSON.parse(localStorage.getItem('trackOrder'));
        trackOrder['order_status'] = this.orderDetailsObj.order_status
        localStorage.setItem('trackOrder',JSON.stringify(trackOrder))
        console.log('trackOrderObj===>267', this.trackOrderObj);
          this.trackOrderObj.order_status =  this.orderDetailsObj.order_status
        console.log('orderDetails',this.orderDetailsObj);
        console.log('trackOrderObj===>271',trackOrder);
        console.log('trackOrderObj===>274', this.trackOrderObj);

      }

    },err=>{})
  }
  
  BlockFuture(){
    $(()=>{
      this.dtToday = new Date();
      this.month = this.dtToday.getMonth() + 1;
      this.day = this.dtToday.getDate();
    this.year = this.dtToday.getFullYear();
     if(this.month < 10)
       this.month = '0' + this.month.toString();
     if(this.day < 10)
        this.day = '0' + this.day.toString();
     this.maxDate = this.year + '-' + this.month + '-' + this.day;
   
     $('#eventDate').attr('max', this.maxDate);
     $('#eventDate1').attr('max', this.maxDate);
    });
  }

  // show spinner 

  showSpinner(){
    this.Spinner.show()
  }

  // hide spinner 
  hideSpinner(){
    setTimeout(() => {
      this.Spinner.hide()
    }, 1000);
  }


 /** Function to show success toast */
 showSuccToast(msg) {
  this.toastr.success(msg);
}

/** Function to show error toast */
 showErrToast(msg) {
  this.toastr.error(msg, "WHOOSH");
}

/** Function to show warning toast */
 showWarnToast(msg) {
  this.toastr.warning(msg, "WHOOSH");
}

/** Function to show info toast */
 showInfoToast(msg) {
  this.toastr.info(msg, "WHOOSH");
}


}
