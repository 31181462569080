import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { NgxSpinnerService } from 'ngx-spinner';


declare var $: any;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  search$ = new Subject();
  restaurantList: any = [];
  dishesArr: any=[];
  dishesList: any=[];
  length: any;
  rest: any;
  pages: number = 1;
  page : number = 10;
  selectedTab: any="Restaurants";
  searchKey: any='';
  selectedObj: any={};
  selectedDish_Res: any={};
  options:any = false
  cartArr: any=[];
  item_modifierArr: any=[];
  selectedIndex: any;
  restIndex: any;
  selectedCartIndex: any;
  itemIndex: any;
  constructor(private service: DataserviceService,public router:Router, 
    public spinner: NgxSpinnerService) {
      // cart data get here 
      this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
         console.log('cartData',this.cartArr)
         this.service.cartLength.next( this.cartArr.length) 

     }

  ngOnInit() {
    this.subscribeToSearch();
  }

  subscribeToSearch() {
    this.service.showSpinner()
    this.search$.pipe(debounceTime(1000)).subscribe((searchKey:any)=>{
      this.service.hideSpinner()

      this.searchKey = searchKey.trim();
      if(this.searchKey.length == 0) {
        return;
      }
      if(this.selectedTab == 'Restaurants'){
        this.searchRestaurantsApi(this.searchKey)
      }else if(this.selectedTab == 'Dishes'){
        this.searchDishesApi(this.searchKey)
      }
    })
  }
 //--------------------------------TO NAVIGATE RESTAURANT PAGE ---------------------------------------//
  navigateToRestaurant(data) {
    localStorage.setItem('restaurantDetail',JSON.stringify(data));
    this.router.navigate(['restaurant'])
  }
  //-------------------------------TO INTEGRATE RESTAURANT API---------------------------------------//

  searchRestaurantsApi(searchKey){
    let url = `customer/restaurants?search=${searchKey}&long=${localStorage.getItem('longitude')}&lat=${localStorage.getItem('latitude')}`
    if(localStorage.getItem('deliveryType')) {
      url = url + `&${(localStorage.getItem('deliveryType'))}`
    }
    this.service.showSpinner()
    this.service.getApi(url).subscribe(res=>{
      this.service.hideSpinner()
      this.spinner.hide()
      if (res.status == 200){
      this.restaurantList = res.body.results;
      // this.service.showSuccess(res.body.message)
      }
    },err=>{
      this.spinner.hide()
    //  if(err.status == 400){
    //    this.service.toastErr(err.error.message);
    //  }
    })
  }
  //-------------------------------------TO INTEGRATE SEARCH DISHES API -------------------------------//
  searchDishesApi(searchKey){
    this.searchKey = searchKey;
    this.spinner.show();
    let url = `customer/item-search?search=${searchKey}&lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`
    if(localStorage.getItem('deliveryType')) {
      url = url + `&${(localStorage.getItem('deliveryType'))}`
    }
    this.service.getApi(url).subscribe(res=>{
    this.spinner.hide()
    if(res.status == 200){
      this.dishesArr = res.body;
      // this.service.showSuccess(res.body.message)
      this.dishesArr.forEach(ele => {
        ele.items.forEach((x,ind)=> {
          x['quantity']=0;
          this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
          let index = this.cartArr.findIndex((x)=>x.restaurant_id == ele.id);
          this.cartArr.forEach((res,i)=>{
            if(index != -1) {
              if(ind == i){
                x['quantity'] = res.quantity
              }
            }
          })
         
        });
      });
      console.log('dish array',this.dishesArr)
     }
    },err=>{
      this.service.hideSpinner()
      // if(err.status == 400){
      //   this.service.toastErr(err.error.message)
      // }else 
      if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
    })
  }
  //-------------------------------TO SEARCH BY FILTER RESTAURANT & DISHES -----------------------------//
  searchByFilter(type){
    let sub_url = '';
    if( this.selectedTab == 'Dishes'){
      sub_url='item-search'  
    }else{
      sub_url='restaurants'
    }
    let url;
    if(type=='cost_for_two') {
      url=`customer/${sub_url}?search=${this.searchKey}&lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}&cost_for_two=true`;
    }else if(type == 'delivery_time'){
      url=`customer/${sub_url}?search=${this.searchKey}&lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}&delivery_time=true`;
    }else if(type == 'rating'){
      url=`customer/${sub_url}?search=${this.searchKey}&lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}&rating=true`;
    }else if(type == 'distance') {
      url=`customer/${sub_url}?search=${this.searchKey}&lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`
    }

    if((localStorage.getItem('deliveryType'))) {
      url = url + `&${(localStorage.getItem('deliveryType'))}`
    }
    this.service.getApi(url).subscribe((res : any)=>{
      if(res.status == 200) {
        if(this.selectedTab == 'Dishes') {
          this.dishesArr = res.body;
        }else {
          this.restaurantList = res.body.results
        }
        this.rest = res.body.next
        this.length = res.body.count;
      }
    },error=>{
    if (error.status == 401 ) {
      this.service.toastErr(error.error.detail || error.error.message)
    }else if(error.status == 400){
      this.restaurantList =[]
    }else if(error.status == 403){
      this.service.toastErr(error.error.detail)
      this.router.navigate([''])
    }
  })

  }
  //--------------------------------------PAGNATION ----------------------------------------------------//
  pageChange(page) {
    console.log(page)
    this.pages = this.rest.next;
  }
 
  //---------------------------- SELECTED TAB FOR RESTAURANT & DISHES-----------------------------------//
  chooseTab(tab) {
    this.selectedTab = tab
    if(tab == 'Restaurants'){
      this.searchRestaurantsApi(this.searchKey)
    }else if(tab == 'Dishes'){
      this.searchDishesApi(this.searchKey)
    }
  }


  //---------------------------------ADD DISH CUSTOMIZE ---------------------------//
  addDishCustome(item,data,index1,restIndex){
    this.restIndex = restIndex;
    this.itemIndex = index1 ;
    this.selectedObj = {...item};
    this.selectedDish_Res = {...data};
    localStorage.setItem('restaurantDetail',JSON.stringify(this.selectedDish_Res));

    console.log(' this.selectedDish_Res', this.selectedDish_Res);
    console.log(' this.selectedObj', this.selectedObj)

    if(localStorage.getItem('myCart')){
      let myCart = JSON.parse(localStorage.getItem('myCart'))
      if(myCart.length > 0) {
        let index = myCart.findIndex((x)=>x.restaurant_id == data.id);
        if(index == -1) {
          this.showCartResetModal(this.itemIndex , this.restIndex)
        }else {
          this.addItemToCart(this.selectedObj,index1,this.restIndex)
        }
        // this.addItemToCart(this.selectedObj,index1,this.restIndex)
        return;
      }
      else {
        let index = myCart.findIndex((x)=>x.restaurant_id == data.id);
        if(index == -1) {
          this.showCartResetModal(this.itemIndex , this.restIndex)
        }else {
          this.addItemToCart(this.selectedObj,index1,this.restIndex)
        }
      }
    }else{
      this.addItemToCart(this.selectedObj,index1,this.restIndex)

    }
  }

  showCartResetModal(itemIndex ,restIndex ) {
    this.itemIndex = itemIndex ;
    this.restIndex = restIndex ;
    $('#CartRefresh').modal({ backdrop: 'static', keyboard: false })
  }

  startFreshYes(itemIndex ,restIndex) {
    localStorage.removeItem('myCart');
    this.selectedIndex = '';
    this.restIndex = '' ;
    this.dishesArr.forEach((ele,restINdex) => {
      ele.items.forEach((x ,i,arr)=> {
        x.quantity = 0 ;
      });
    });
    
    this.addItemToCart(this.selectedObj,itemIndex ,restIndex)
  }

  addItemToCart(data ,index1 ,restIndex) {
    console.log('data',data);
    console.log('index1',index1);
    this.restIndex = restIndex ;
    this.selectedIndex = index1
    this.selectedObj = {...data};
    if(this.selectedObj.option == true){
      this.selectedObj.item_option.map(item =>{item['selected']=false})
    }
    if(this.selectedObj.modifiers == true){
      this.selectedObj.item_modifiers.map(item =>{item['selected']=false})
    }
    this.selectedObj['total_pay']=data.selling_price
    if(data.option == true || data.modifiers == true)  {
      this.item_modifierArr= []
      $("#Customize1").modal({ backdrop: 'static', keyboard: false })
    }else {
      this.addToCart(this.selectedObj,index1 , this.restIndex)
    }
    // this.items.forEach(ele => {
    //   if (ele.id == id) {
    //     this.item_option = ele.item_option
    //     this.item_modifiers = ele.item_modifiers
    //   }
    // });
    // console.log('this.item_list', this.item_option)
    // console.log('this.item_modifiers', this.item_modifiers)
  }


  addToCart(data,selectedIndex ,restIndex) {
    this.restIndex = restIndex ;
    console.log('add to cart data', data,'selectedIndex',selectedIndex);
    console.log('restIndex',restIndex);

    this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
    data['quantity'] = 1;
    data['restaurant_name'] = this.selectedDish_Res.restaurant_name;
    data['restaurant_image'] = this.selectedDish_Res.restaurant_image;
    data['restaurant_id'] = this.selectedDish_Res.id ;
    data['selected_modifiers'] =  this.item_modifierArr;

   this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
   this.cartArr.push(data)
    localStorage.setItem('myCart',JSON.stringify( this.cartArr))
    this.service.cartLength.next( this.cartArr.length) ;
    this.increaseQuantity(data,selectedIndex, this.restIndex)
    // if (data.modifiers == false || data.option == false){
    //   this.increaseQuantity(data,selectedIndex, this.restIndex)
    // }
  }

  // decrement of quantity

  decreaseQuantity(item,index , j){ 
      this.dishesArr.forEach((ele,restINdex) => {
        ele.items.forEach((x ,i,arr)=> {
          if (restINdex == j){
          if (i==index){
            if (x.quantity>=1){
              x.quantity = x.quantity - 1;
              this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
             if (this.cartArr){
              this.cartArr.forEach((res,ind)=>{
                // if(ind==index){
                  res.quantity= res.quantity - 1 
                  this.cartArr[ind].quantity = res.quantity ;
                  if ( this.cartArr[ind].quantity ==0){
                    this.cartArr.splice(index,1);
                  }
                // }
              })
            }
            }
           this.service.cartLength.next(this.cartArr.length)
            localStorage.setItem('myCart',JSON.stringify(this.cartArr));
            console.log ( x.quantity)
          }
        }
        });
      });
      
      console.log(' this.dishesArr', this.dishesArr);
  }
 

  // increment of quantity

  increaseQuantity(item,index,j){
    this.selectedCartIndex = index

    // if(item.modifiers == true || item.option == true){
    //   $("#CartModal").modal({ backdrop: 'static', keyboard: false })
    // }
    console.log('item', item,'index',index, 'restaurent index',j);
    // console.log('restIndex',restIndex);
   console.log('item',item);
      this.dishesArr.forEach((ele,resTIndex) => {
        ele.items.forEach((x ,i,arr)=> {
          console.log('i',i,'Index value',index);
          console.log('item res',x);
          if (resTIndex == j) {
          if (i==index){
            if (x.quantity>0){
              x.quantity = x.quantity + 1;
              this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : []
             if (this.cartArr){
              this.cartArr.forEach((res,ind)=>{
                  this.cartArr[ind].quantity= res.quantity + 1 ;
                  console.log(' this.cartArr', this.cartArr);
                  localStorage.setItem('myCart',JSON.stringify(this.cartArr))
              })
            }
            }
            else{
              console.log('item qty',item.quantity);
              x.quantity = item.quantity
              if (x.quantity == 0){
                x.quantity =   x.quantity + 1
              }
            }
          
            this.service.cartLength.next(this.cartArr.length)
            localStorage.setItem('myCart',JSON.stringify(this.cartArr));

            console.log ( x.quantity)
          }
          else{
            x.quantity = item.quantity;
            console.log ('========>359', x.quantity)
          }
        }
        
        });
      });
      console.log(' this.dishesArr', this.dishesArr);   
  }
 
  repeatOrder(){
    this.cartArr[this.selectedCartIndex].quantity = this.cartArr[this.selectedCartIndex].quantity + 1;
    localStorage.setItem('myCart',JSON.stringify(this.cartArr))
  }


  calculateTotalPay(item,event,index,type){
    // Manage one checkbox will select at a time
 
    if (type  == 'option'){
     this.selectedObj.total_pay = this.selectedObj.selling_price
     if(this.selectedObj.option == true) {
       if(event == true){
         this.selectedObj.item_option.forEach((x)=>{
           if (x.id !==item.id){
             x['is_disabled']= true;
           }
         })
       }
       else {
         this.selectedObj.item_option.forEach((x)=>{
             x['is_disabled']= false;        
         })
       }
 
       this.selectedObj.item_option.forEach(item => {
         if(item.selected == true){
           this.selectedObj.total_pay = 0
           this.selectedObj.total_pay= this.selectedObj.total_pay + item.selling_price ;
           console.log('total pay', this.selectedObj.total_pay);
           console.log('item.selling_price',item.selling_price);
         }
       });  
     }
   
     // modifier with type option 
 
     this.selectedObj.item_modifiers.forEach(item => {
       if (item.selected == true) {
         this.selectedObj.total_pay = this.selectedObj.total_pay + item.selling_price;
       }
     });
     console.log(' this.selectedObj.total_pay', this.selectedObj.total_pay);

    
    }
 
     
     // Modifiers logic start here
     if (type == 'modifiers'){
       if(this.selectedObj.modifiers == true) {
         console.log('selectedObj modifiers',this.selectedObj.item_modifiers);
         if (item.selected == true) {
           this.selectedObj.total_pay = this.selectedObj.total_pay + item.selling_price;
         }
         else{
           this.selectedObj.total_pay = this.selectedObj.total_pay - item.selling_price;
         }
         console.log('this.selectedObj.total_pay',this.selectedObj.total_pay);

         if (item.selected == true) {
           this.item_modifierArr.splice(index,0,item.id)       
         }
         else if (item.selected == false) {
         this.item_modifierArr.splice(index, 1);
         }
         console.log('item_modifierArr',this.item_modifierArr);
       }
     }
     
 
 
            // Modifiers logic end here         
   }



}
