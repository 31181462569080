import { Component, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-viewnews',
  templateUrl: './viewnews.component.html',
  styleUrls: ['./viewnews.component.css']
})
export class ViewnewsComponent implements OnInit {
 
  viewNews: any;
  id: string;
  lang: string = 'en';
  constructor(private dataservice : DataserviceService,private spinner : NgxSpinnerService,
    private activated: ActivatedRoute) {
    // this.activated.params.subscribe(params => {
    //   this.id = params.id;
    //   console.log(' this.id', this.id);
      
    // });
    this.dataservice.emmiter.subscribe();
   this.dataservice.emmiter.subscribe((res:any)=>{
    this.id = res
   });


   }

  ngOnInit() {
    console.log(this.id)
this.newsDetails();
    window.scrollTo(0, 0);
    this.dataservice.language.subscribe((res)=>{
      this.lang  = res;
      if(this.lang == 'th'){
        this.newsDetails();
      }
      else{
        this.newsDetails(); 
      }
    })
  }
  //...................................news...............................//
  async newsDetails(){
    console.log(this.id)
    let url = `content/news/${this.id}`
    if(this.lang == 'th'){
       url = `content/news/${this.id}/th`
      }
      this.spinner.show()
    await this.dataservice.getApi(url).subscribe((res: any) => {
      console.log('response', res);
      
      if (res.status == 200) {
      
        this.viewNews = res.body;
        setTimeout(() => {
          
          this.spinner.hide()
        }, 1000);
      }
      else{
        this.spinner.hide()
      }
    }, (error) => {
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.dataservice.toastErr(error.error.detail)
      }
    })
  }

  
}
