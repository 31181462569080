import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { DataserviceService } from '../../Service/dataservice.service';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeComponent } from '../home/home.component';
// import { FacebookLoginProvider, AuthService } from 'angularx-social-login';
declare var google: any;
declare var $: any;

@Component({
  selector: 'app-header-footer',
  templateUrl: './header-footer.component.html',
  styleUrls: ['./header-footer.component.css']
})
export class HeaderFooterComponent implements OnInit {

  location: any = localStorage.getItem('address')?localStorage.getItem('address'):'';
  data1: any;
  data: any;
  loginMessage1: any;
  signUpMessage: any;
  loginMessage: any;
  firstName: any;
  cell: any;
  token: any;
  otpForm: FormGroup;
  loginForm: FormGroup;
  mobile: any;
  uuid: any;
  currUrl: string;
  currUrl2: string;
  signupForm: FormGroup;
  isValidNumber: any;
  myCode: string;
  loggedIn: any = localStorage.getItem('status')?localStorage.getItem('status'):"beforelogin";
  languageValue: any = "English";
  flag: boolean = false;

  constructor(private service: DataserviceService,
    private router: Router,
    private formBuilder: FormBuilder,
    // public authService: AuthService,
    private translate: TranslateService) {

    this.checkLoginStatus()
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currUrl = event.url.split('/')[1];
        this.currUrl2 = event.url.split('/')[2];
        this.currUrl = (this.currUrl.indexOf('?') != -1) ? this.currUrl.substr(0, this.currUrl.indexOf('?')) : this.currUrl
        if (localStorage.getItem(`token`) != null) {
          this.service.changeLoginSub(localStorage.nexttoken);
          if (!(this.currUrl == `viewnews` || this.currUrl == `recommended` || this.currUrl == `nearyou` || this.currUrl == `thankyou` || this.currUrl == `profile`
            || this.currUrl == `blockedrestaurants` || this.currUrl == `help` || this.currUrl == `search` || this.currUrl == `restaurant` || this.currUrl == `restaurant2`
            || this.currUrl == `restaurant3` || this.currUrl == `account1` || this.currUrl == `account2` || this.currUrl == `cart`)) {
            this.router.navigate([`/nearyou`])
          }
          if (this.currUrl2 == `termsconditions` || this.currUrl2 == `aboutus` || this.currUrl2 == `contactus` || this.currUrl2 == `privacypolicy`
            || this.currUrl2 == `faqs` || this.currUrl2 == `blogs` || this.currUrl2 == `news` || this.currUrl2 == `refund` || this.currUrl2 == `partner`
            || this.currUrl2 == `ride`) {
            this.router.navigate([`services/` + this.currUrl2])
          }
        }
      }
    })
  }

  ngOnInit() {

    
    this.checkLoginStatus()
    this.phoneCheckCountry();
    this.signUp();
    this.logedIn();
    this.otp();
    this.firstName = localStorage.getItem('firstname')
  }

  logedIn() {
    this.loginForm = this.formBuilder.group({
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{9,13}$/)]))
    })
  }

  signUp() {
    this.signupForm = this.formBuilder.group({
      'phoneNo': new FormControl('', Validators.compose([Validators.required])),
      "name": ["", Validators.required],
      "email": new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      "password": new FormControl('', Validators.compose([Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)])),
    })
  }

  otp() {
    this.otpForm = this.formBuilder.group({
      "phnumber": new FormControl(''),
      'otp': new FormControl('', Validators.required)
    })
  }

  signupPage() {
    $("#signupModal").modal({ backdrop: 'static', keyboard: false })
    this.cellCheckCountry();
  }

  closeModalSignup() {
    $("#signupModal").modal('hide')
    this.signupForm.reset();
  }

  loginModalOpen() {
    $("#loginModal").modal({ backdrop: 'static', keyboard: false })
    this.phoneCheckCountry();
  }

  loginModalClose() {
    $("#loginModal").modal('hide')
    this.loginForm.reset()
  }

  /* to check login status */
  checkLoginStatus() {
    if (localStorage.getItem('token')) {
      this.location = localStorage.getItem('address')
    this.loggedIn = localStorage.getItem('status')
      this.loggedIn = "afterlogin";
    }
    else {
      this.location = localStorage.getItem('address')
    this.loggedIn = localStorage.getItem('status')
      if (localStorage.getItem('status') == "delivery") {
        this.loggedIn = "delivery";
      }
      else {
        this.loggedIn = "beforelogin";
      }
    }
  }

  phoneCheckCountry() {
    $("#phoneNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  toCheckSpaceChar() {
    this.isValidNumber = $('#phoneNum').intlTelInput('isValidNumber');
    const countryData = $('#phoneNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }

  cellCheckCountry() {
    $("#cellNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  CheckSpaceChar() {
    this.isValidNumber = $('#cellNum').intlTelInput('isValidNumber');
    const countryData = $('#cellNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }


  signUpModal() {

    if (this.loginMessage1 == "OTP has been sent to your register number. Please Verify your number.") {
      var data = {
        "first_name": this.signupForm.value.name,
        "mobile": this.signupForm.value.phoneNo,
        "email": this.signupForm.value.email,
        "password": this.signupForm.value.password
      }
      console.log('data before sign up', data)
      this.service.postApi('customer/signup', data).subscribe((res: any) => {
        console.log('signup response', res)
        if (res.status == 200 || res.status == 201) {
          this.signUpMessage = res.body.message
          console.log('signup message', this.signUpMessage)
          $('#signupModal').modal('hide')
          $('#otpModal').modal({ backdrop: 'static', keyboard: false })
          this.cell = res.body.mobile
          this.uuid = res.body.uuid
          this.otpForm.patchValue({
            phnumber: this.cell
          })
        }
      }, error => {
        console.log('error', error)
        if (error.status == 401 || error.status == 403) {
          this.service.toastErr(error.error.detail)
        }
      })
    }
    else {
      var data1 = {
        "first_name": this.signupForm.value.name,
        "mobile": this.myCode + this.signupForm.value.phoneNo,
        "email": this.signupForm.value.email,
        "password": this.signupForm.value.password
      }
      this.service.postApi('customer/signup', data1).subscribe((res: any) => {
        console.log('signup response', res)
        if (res.status == 200 || res.status == 201) {
          this.signUpMessage = res.body.message
          console.log('signup message', this.signUpMessage)
          $('#signupModal').modal('hide')
          $('#otpModal').modal({ backdrop: 'static', keyboard: false })
          this.cell = res.body.mobile
          this.uuid = res.body.uuid
          this.otpForm.patchValue({
            phnumber: this.cell
          })
        }
      }, error => {
        console.log('error', error)
        if (error.status == 401 || error.status == 403) {
          this.service.toastErr(error.error.detail)
        }
      })

    }
  }


  login() {
    var data = {
      "mobile": this.myCode + this.loginForm.value.phone,
    }
    this.service.postApi('customer/login', data).subscribe((res: any) => {
      console.log('login response', res)
      if (res.status == 200) {
        this.loginMessage = res.body.message
        console.log('login message', this.loginMessage)
        this.loginForm.reset()
        this.uuid = res.body.uuid
        localStorage.setItem('uuid', this.uuid)
        $('#loginModal').modal('hide')
        $('#otpModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.otpForm.patchValue({
          phnumber: this.mobile
        })
      }
      else if (res.status == 201) {
        this.loginMessage1 = res.body.message
        console.log('login message1', this.loginMessage1)
        this.loginForm.reset()
        $('#loginModal').modal('hide')
        $('#signupModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.signupForm.patchValue({
          phoneNo: this.mobile
        })
      }
    })
  }

  verify() {
    var data = {
      "code": this.otpForm.value.otp
    }
    this.service.postApi('customer/otp-verification/' + this.uuid, data).subscribe((res: any) => {
      if (res.status == 200) {
        $('#signupModal').modal('hide')
        if (this.loginMessage == 'OTP has been sent to your register number.') {
          this.loggedIn = 'afterlogin';
          this.token = localStorage.setItem("token", res.body.token);
          this.firstName = res.body.first_name
          localStorage.setItem('firstname', this.firstName)
          this.router.navigateByUrl('/nearyou')
        }
        else if (this.signUpMessage == 'Account created successfully.') {
          this.loggedIn = 'beforelogin'
          this.router.navigateByUrl('/home')
        }
      }
    })
  }

  homenavigate() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/nearyou'])
    }
    else {
      localStorage.clear()
      this.router.navigate(['/home'])
    }
  }

  logout() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('firstname');
    // localStorage.removeItem('status')
    // localStorage.removeItem('mobile')
    this.service.logOut()
    this.router.navigateByUrl('/home')
    this.loggedIn = 'beforelogin';
  }

  changeLanguage(event) {
    this.languageValue = event;
    if (this.languageValue == 'English') {
      this.flag = false;
      this.translate.use('en');
    } else {
      this.flag = true;
      this.translate.use('th');
    }
  }

}
