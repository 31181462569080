import { Component, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
declare var $:any;
@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit {
  loginForm: FormGroup;
  signupForm: FormGroup;
  otpForm: FormGroup;
  isValidNumber: any;
  myCode: any='+91';
  loginMessage1: string;
  signUpMessage: any;
  cell: any;
  uuid: any;
  loginMessage: any;
  mobile: any;
  loggedIn: string;
  token: any;
  firstName: any;
  languageValue: any = "English";
  flag: boolean = false;
  location: any;

  constructor(private service: DataserviceService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService) { 
      // location.href='http://localhost:4201/nearyou'
    }

  ngOnInit() {
    this.token=localStorage.getItem('token')?localStorage.getItem('token'):''
    this.firstName=localStorage.getItem('firstname')
    this.logedIn();
    this.signUp();
    this.otp()
  }

  logedIn() {
    this.loginForm = this.formBuilder.group({
      'phone': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{9,13}$/)]))
    })
  }

  signUp() {
    this.signupForm = this.formBuilder.group({
      'phoneNo': new FormControl('', Validators.compose([Validators.required])),
      "name": ["", Validators.required],
      "email": new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      "password": new FormControl('', Validators.compose([Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)])),
    })
  }

  otp() {
    this.otpForm = this.formBuilder.group({
      "phnumber": new FormControl(''),
      'otp': new FormControl('', Validators.required)
    })
  }

  signupPage() {
    $("#signupModal").modal({ backdrop: 'static', keyboard: false })
    this.cellCheckCountry();
  }

  closeModalSignup() {
    $("#signupModal").modal('hide')
    this.signupForm.reset();
  }

  loginModalOpen() {
    $("#loginModal").modal({ backdrop: 'static', keyboard: false })
    this.phoneCheckCountry();
  }

  loginModalClose() {
    $("#loginModal").modal('hide')
    this.loginForm.reset()
  }

  phoneCheckCountry() {
    $("#phoneNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  toCheckSpaceChar() {
    this.isValidNumber = $('#phoneNum').intlTelInput('isValidNumber');
    const countryData = $('#phoneNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }

  cellCheckCountry() {
    $("#cellNum").intlTelInput({
      autoPlaceholder: false,
      autoFormat: false,
      autoHideDialCode: false,
      initialCountry: 'in',
      nationalMode: false,
      onlyCountries: [],
      preferredCountries: ["us"],
      formatOnInit: true,
      separateDialCode: true,
      formatOnDisplay: false
    });
  }

  CheckSpaceChar() {
    this.isValidNumber = $('#cellNum').intlTelInput('isValidNumber');
    const countryData = $('#cellNum').intlTelInput('getSelectedCountryData');
    this.myCode = "+" + countryData.dialCode;
  }

  signUpModal() {

    if (this.loginMessage1 == "OTP has been sent to your register number. Please Verify your number.") {
      var data = {
        "first_name": this.signupForm.value.name,
        "mobile": this.signupForm.value.phoneNo,
        "email": this.signupForm.value.email,
        "password": this.signupForm.value.password,

        "country_code": "+91",
      	"last_name":"Negi",



      }
      console.log('data before sign up', data)
      this.service.postApi('customer/signup', data).subscribe((res: any) => {
        console.log('signup response', res)
        if (res.status == 200 || res.status == 201) {
          this.signUpMessage = res.body.message
          console.log('signup message', this.signUpMessage)
          $('#signupModal').modal('hide')
          $('#otpModal').modal({ backdrop: 'static', keyboard: false })
          this.cell = res.body.mobile
          this.uuid = res.body.uuid
          this.otpForm.patchValue({
            phnumber: this.cell
          })
        }
      }, error => {
        console.log('error', error)
        if (error.status == 401 || error.status == 403) {
          this.service.toastErr(error.error.detail)
        }
      })
    }
    else {
      let full_name  = this.signupForm.value.name;                       // split , splice, join
      let arr = full_name.split(' ')
      let f_name = arr[0]
      arr.splice(0,1)
      let l_name = arr.join(' ')
      var data1 = {
        "first_name": f_name,
        "mobile":this.signupForm.value.phoneNo,
        "email": this.signupForm.value.email,
        "password": this.signupForm.value.password,
        "country_code":  this.myCode,
      	"last_name":l_name,
      }
      this.service.postApi('customer/signup', data1).subscribe((res: any) => {
        console.log('signup response', res)
        if (res.status == 200 || res.status == 201) {
          this.signUpMessage = res.body.message
          console.log('signup message', this.signUpMessage)
          $('#signupModal').modal('hide')
          $('#otpModal').modal({ backdrop: 'static', keyboard: false })
          this.cell = res.body.mobile
          this.uuid = res.body.uuid
          this.otpForm.patchValue({
            phnumber: this.cell
          })
        }
      }, error => {
        console.log('error', error)
        if (error.status == 401 || error.status == 403) {
          this.service.toastErr(error.error.detail)
        }
      })

    }
  }


  login() {
    var data = {
      "mobile": this.myCode + this.loginForm.value.phone,
    }
    this.service.postApi('customer/login', data).subscribe((res: any) => {
      console.log('login response', res)
      if (res.status == 200) {
        this.loginMessage = res.body.message
        console.log('login message', this.loginMessage)
        this.loginForm.reset()
        this.uuid = res.body.uuid
        localStorage.setItem('uuid', this.uuid)
        $('#loginModal').modal('hide')
        $('#otpModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.otpForm.patchValue({
          phnumber: this.mobile
        })
      }
      else if (res.status == 201) {
        this.loginMessage1 = res.body.message
        console.log('login message1', this.loginMessage1)
        this.loginForm.reset()
        $('#loginModal').modal('hide')
        $('#signupModal').modal({ backdrop: 'static', keyboard: false })
        this.mobile = res.body.mobile
        this.signupForm.patchValue({
          phoneNo: this.mobile
        })
      }
    })
  }

  verify() {
    var data = {
      "code": this.otpForm.value.otp
    }
    this.service.postApi('customer/otp-verification/' + this.uuid, data).subscribe((res: any) => {
      if (res.status == 200) {
        $('#signupModal').modal('hide')
        if (this.loginMessage == 'OTP has been sent to your register number.') {
          this.loggedIn = 'afterlogin';
          this.token = localStorage.setItem("token", res.body.token);
          this.firstName = res.body.first_name,
          
          this.router.navigateByUrl('/nearyou')
          localStorage.setItem('firstname',this.firstName)
        }
        else if (this.signUpMessage == 'Account created successfully.') {
          this.loggedIn = 'beforelogin'
          this.router.navigateByUrl('/home')
        }
      }
    })
  }

  homenavigate() {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/nearyou'])
    }
    else {
      localStorage.clear()
      this.router.navigate(['/home'])
    }
  }

  changeLanguage(event) {
    this.languageValue = event;
    if (this.languageValue == 'English') {
      this.flag = false;
      this.translate.use('en');
    } else {
      this.flag = true;
      this.translate.use('th');
    }
  }

  logoutModal(){
    $('#logOutModal').modal({ backdrop: 'static', keyboard: false })
  }

  logout(){
    this.service.logOut()
  }

}
