import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { NgxSpinnerService } from 'ngx-spinner';


  @Component({
    selector: 'app-signup-ride-us',
    templateUrl: './signup-ride-us.component.html',
    styleUrls: ['./signup-ride-us.component.css']
  })
  export class SignupRideUsComponent implements OnInit {
    restaurentInyourhand: any;
   @Input() signIn:string;
    constructor(
      private activatedRoute: ActivatedRoute,private router : Router, public spinner: NgxSpinnerService,
     public service: DataserviceService
    ) { 
    }
    onselect(){}
    ngOnInit() {
      this.getRestaurentInyourHandApi();
    }

    getRestaurentInyourHandApi() {
      this.spinner.show();
      this.service.getApi('master/restaurant-in-your-hand').subscribe((res: any) => {
        console.log('response', res)
        this.spinner.hide();
        if (res.status == 200) {
           this.restaurentInyourhand= res.body;
           console.log(' this.bannerData', this.restaurentInyourhand)
        }
      }, error => {
        if (error.status == 401 || error.status == 403) {
          this.service.toastErr(error.error.detail)
        }
      })
    }
  

  }
