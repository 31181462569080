import { Component, OnInit, OnDestroy,ViewChild } from '@angular/core';
import { DataserviceService } from '../../Service/dataservice.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgxMaskModule, IConfig } from 'ngx-mask'
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';


declare var $: any;
declare var google:any;
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit,OnDestroy {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  zoom: number = 7;
  lat: number;
  lng: number;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  pastOrderList: any = [];
  saveCardArr:any;
  selectedItem: any={};
  selectedItemIndex: any = null;
  selectedOrderObj: any = {};
  editProfile: FormGroup;
  loginForm: FormGroup;

  email: any;
  phoneNo: any;
  firstName: any;
  otpVerify: FormGroup;
  pymentCardForm: FormGroup;
  newNumber: any;
  uuid: any;
  addressDetailArr: any = []
  viewDetailsObj: any;
  addressForm: FormGroup;
  showOTP: boolean = false;
  showEmail: boolean = false;
  showPass: boolean = false;
  showVerify: boolean = false;
  favouriteListArr: any = [];
  enterNewEmail: any;
  enterNewPass: any;
  enterConfirmPass: any;
  enterOTP: any;
  enterCountryCode: any;
  countryCode: any;
  paymentCardForm: FormGroup;
  getDeliveryCharge: any;


  helpOrderQues_Arr :any = [
    {
      'question': "I haven't received this order",
      'answer': "We are really sorry for this experience. You can try reaching out to our delivery executive or us and we will try to resolve this as soon as possible."
    },
    {
      'question': "Items are missing from my order",
      'answer' : "We are really sorry that you had this experience. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "Items are different from what I ordered",
      'answer' : "We are really sorry that incorrect items were delivered in your order. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "I have packaging or spillage issue with this order",
      'answer': "We are really sorry that your order had packaging issues. Please reach out to us and we will try to resolve this immediately",
    },
    {
      'question': "I have received bad quality food",
      'answer': "Please let us know what items were of bad quality. And we will try to resolve this immediately",
    },
    {
      'question': "The quantity of food is not adequate",
      'answer': "Please let us know what items were inadequate. And we will try to resolve this immediately",
    }

  ]
  pastThreadArr: any=[];
  chatTitle: any;
  myUserId: any;
  message: any;
  imageUrl: any = '';
  restaurantStarRatingArr :any= [
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
  ];
  driverStarRatingArr:any = [
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
  ];
  selectedOrder: any={};
  pos: { lat: number; lng: number; };
  cartSubTotal: any = 0;
  cartArr: any=[];
  selectedItemsArray: any = [];
  getTax: any;
  location: string;
  room_id: any;
  receiverId: any;
  isValidNumber: any;
  chat_type: any;
  CountryCodeItems: any=[];
  getCartListArr: any=[];
  cardId: any;
  url:any;
  favid: any;

  constructor(public service: DataserviceService, private formBuilder: FormBuilder, public spinner: NgxSpinnerService, public router: Router ,public datePipe:DatePipe) { 
    this.myUserId = localStorage.getItem('user_id');
    this.location = localStorage.getItem('address')
    this.cartArr = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')): [];
    console.log( this.cartArr.length);
    if(this.cartArr.length > 0) {
      this.rest_id  = this.cartArr[0].restaurant_id;
      console.log( this.rest_id);
    }
  }

  ngOnInit() {
    this.service.location = localStorage.getItem('address');
    this.viewProfile();
    this.initForm();
    this.viewAddress()
    this.initMap()
    this.paymentCardFormValidation();
    this.checkCart();
    this.logedIn();
    this.CountryCode();

  }
 
  ngOnDestroy(){
    $('#chat-modal').modal('hide')
    if(this.room_id){
      this.closeChat()
    }
  }

  logedIn() {
    this.loginForm = this.formBuilder.group({
      "customer_pcode" : new FormControl('+91',Validators.required),
      'enterMobileNo': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-9][0-9]{9,13}$/)])),
      'enterNewEmail': new FormControl('', Validators.compose([Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/)])),
      "enterNewPass": new FormControl('', Validators.compose([Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)])),
      "enterConfirmPass": new FormControl('', Validators.compose([Validators.pattern(/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/)])),

    })
  }

  toCheckSpaceChar() {
    this.isValidNumber = $('#phoneNum').intlTelInput('isValidNumber');

  }
  CountryCode(){
    let channel = "api/country-list";
    this.service.getApi(channel).subscribe((res:any)=>{
      this.CountryCodeItems = res.body;
      console.log('Code',res)
    })
    }
   

 
  ordersList() {
    this.url =  "https://apifooddelivery.mobiloitte.com/"
    //if (localStorage.getItem('token')){
      this.spinner.show();
    this.service.getApi('customer/order-history').subscribe((res: any) => {
      console.log('response', res)
      this.spinner.hide();
      if (res.status == 200) {
        this.pastOrderList = res.body;
      }
    }, error => {
      this.spinner.hide();
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }
    })
  }

  //----------------------------------------to integrate Chat socket ------------------------------------\\
  openHelp(index,item) {
    this.service.getApi(`customer/order-detail/${item.id}`).subscribe(res=>{
      if(res.status == 200){
      this.selectedOrderObj = item
      this.selectedOrderObj.chat_thread = [...res.body.chat_thread]
      this.selectedItemIndex = index
      this.getPastConversation()
      }
    })
    this.selectedItemIndex = index
    this.selectedOrderObj = item
    this.getPastConversation()
  }


  chatHelpModal(title) {
    this.room_id = ''
    this.service.chatArr = [];
    this.receiverId = (this.selectedOrderObj.order_status != 'Delivered' 
    && this.selectedOrderObj.order_status != 'Cancelled' &&
     this.selectedOrderObj.order_status != 'Rejected' &&
      this.selectedOrderObj.order_status != 'Order Picked' &&
       this.selectedOrderObj.order_status !='Out for Delivery') ? this.selectedOrderObj.merchant_id : localStorage.getItem('support_id');
    this.chat_type = (this.receiverId == this.selectedOrderObj.merchant_id) ? 'm-c' : 'a-c';
    let room = {
    
      'sender_id': localStorage.getItem('user_id'),
      'receiver_id': this.receiverId,
      'order_id':this.selectedOrderObj.id ,
      'chat_type': this.chat_type,
      'support': title,
      "message": title,
      'message_type': 'text',
      'time': new Date().getTime()
  
  }
    // this.service.chatArr.push(room.room)
    this.chatTitle = title
    this.service.connectSocket(room);
    this.message = title;
    this.service.socket.addEventListener('open', (event) => {
    $('#chat-modal').modal({ backdrop: 'static', keyboard: false })
    });
    // Listen for messages
    this.service.socket.addEventListener('message', (event)=> {
      console.log('Message from server ', event.data);
      let eventData = JSON.parse(event.data)
      if(eventData.id){
        this.room_id = eventData.room_id
      }
    });
  }

  helpWithOrders () {
    this.selectedItemIndex = null
    //if (localStorage.getItem('token')){
    this.spinner.show();
    this.service.getApi('customer/order-history').subscribe((res: any) => {
    this.spinner.hide();
      if (res.status == 200) {
        this.pastOrderList = res.body;
        
      }
    }, error => {
      this.spinner.hide();
      console.log('error', error)
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail);
         
      }
    })
  }

   //----------leave chat ----------------//
   closeChat(){
    // this.room_id = this.service.chatArr.length > 0 ? this.service.chatArr[0].room_id : this.room_id
    // let request = {
    //   'action':'leave',
    //   'room': {
    //       'id':this.room_id
    //   }
    // } 
    let request = {
      'id':this.service.room_id
    } 
    this.service.socket.send(JSON.stringify({id:this.service.room_id}))
    this.service.socket.close()
  }


  getPastConversation() {
    this.pastThreadArr =  this.selectedOrderObj.chat_thread
    // this.pastThreadArr = []
    // let url = `customer/all-conversation-threads`;
    // this.spinner.show();
    // this.service.getApi(url).subscribe((res: any) => {
    //   this.spinner.hide();
    //   if (res.status == 200) {
    //     this.pastThreadArr =  res.body;
    //   }
    // }, err => {
    //   this.spinner.hide();
    //  })
  }
  
  
  backallOrder() {
    this.selectedItemIndex = null
  }

  // viewDetails(orderObj) {
  //   this.selectedOrderObj = orderObj

  // }
  viewDetails(order_id){
    this.service.getApi(`customer/order-detail/${order_id}`).subscribe(res=>{
      if(res.status == 200){
      this.selectedOrderObj = res.body
      }
    },err=>{
      this
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }
    })
  }

  sendMessage(type) {
    let message ;
    console.log(type);
    this.room_id = this.service.chatArr.length > 0 ? this.service.chatArr[0].room_id :''
    if(type == 'text') {
      message = {
        "room_id" : this.room_id,
        "message": this.message,
        "sender_id":localStorage.getItem('user_id'),
        "receiver_id":this.receiverId, 
        "image": "",
        'order_id':this.selectedOrderObj.id ,
        "message_type": "text",
        "chat_type" : this.chat_type,
        "time": new Date().getTime()
      
    }
    }else {
      message = {
        "room_id" : this.room_id,
        "message": '',
        "sender_id":localStorage.getItem('user_id'),
        "receiver_id":this.receiverId, 
        "image": this.imageUrl,
        "message_type": type,
        "chat_type" : this.chat_type,
        "time": new Date().getTime(),
        'order_id':this.selectedOrderObj.id ,

    }
    }
    // this.service.chatArr.push(message.messages);
    this.message = ""
    this.service.chatArr.push(message)
    console.log(this.service.chatArr)
    this.service.socket.send(JSON.stringify(message))
    this.automaticScroll();
  }


  uploadImage(event) {
    if(event.target.files && event.target.files[0]) {
      console.log(event.target.files[0].type)
      if((event.target.files[0].type == 'image/png') || (event.target.files[0].type == 'image/jpg') || (event.target.files[0].type == 'image/jpeg') ||(event.target.files[0].type == 'image/gif') || (event.target.files[0].type == 'image/pdf') ) {
        let file = event.target.files[0];
        let formdata =  new FormData();
        formdata.append('image',file);
        this.spinner.show();
        this.service.postApi('merchant/image',formdata).subscribe(res=>{
          this.spinner.hide();
          if(res.status == 200){
            this.imageUrl = res.body['file_name'];
            this.sendMessage(event.target.files[0].type) 
          }
        },err=>{
          this.spinner.hide();
          this.imageUrl = '';
          this.service.toastErr(err.error.message)
        })
      }else {
        this.service.toastErr('Only jpg,jpeg,png,gif and pdf are supported!')
      }
    }
  }
  
  automaticScroll() {
    let container = document.getElementById('chatBox');
      container.scrollTop = container['scrollHeight'];
  }


  getChatHistory(item,message) {
    this.room_id = item.id
    this.service.chatArr = [];
    this.chatTitle = item.title;
    this.receiverId = (this.selectedOrderObj.order_status != 'Delivered' && this.selectedOrderObj.order_status != 'Cancelled' && this.selectedOrderObj.order_status != 'Rejected' && this.selectedOrderObj.order_status != 'Order Picked' && this.selectedOrderObj.order_status != 'Out for Delivery') ? this.selectedOrderObj.merchant_id : localStorage.getItem('support_id');
    this.chat_type = (this.receiverId == this.selectedOrderObj.merchant_id) ? 'm-c' : 'a-c';
    this.service.getApi(`chat/chat-history/${item.id}`).subscribe(res=>{
      if(res.status == 200) {
        this.service.chatArr = [...res.body];
        this.service.chatArr.map(ele=>{
          ele['sender_id'] = ele.sender
          ele['receiver_id'] = ele.receiver
          ele['time']= new Date(ele.created_at).getTime()
        })
        let room = {
    
          'sender_id': localStorage.getItem('user_id'),
          'receiver_id': this.receiverId,
          'order_id':this.selectedOrderObj.id ,
          'chat_type': this.chat_type,
          "message": "Hi",
          'message_type': 'text',
          'time': new Date().getTime()
      
      }
      this.service.connectSocket(room);
        this.chatTitle = message
        $('#chat-modal').modal({ backdrop: 'static', keyboard: false })
        this.automaticScroll();
      }
    },err=>{
      
    })  
  }



  //------------------------------------------ To Rate Post Api -------------------------\\
  rateMark(){
    let count = 0;
    let api1 = false , api2 =false;
    this.restaurantStarRatingArr.map(item=> {
      if(item.marked == true) {
        count = count + 1;
      }
    })
    let data = {
    "restaurant":this.selectedOrder.restaurant_id,
    "location":this.selectedOrder.restaurant_address.id,
    "order":JSON.stringify(this.selectedOrder.id) ,
    "rating": count
    }
    this.service.postApi(`customer/rating-restaurant`,data).subscribe(res =>{
      if(res.status == 201){
        api1 = true;
        if(api1 && api2) {
          this.ordersList();
        }
      }
    },err =>{
      if(err.status == 400){
        this.service.toastErr(err.error.message);
      }
    })
    

    //--------------------------- driver rating API--------------------
    let count2 = 0;
    this.driverStarRatingArr.map(item=>{
      if(item.marked == true){
        count2 = count2 + 1;
      }
    })

     let data2 = {
    "driver":this.selectedOrder.driver_id ,
	  "order":this.selectedOrder.id,
	  "rating":count2
    }
    this.service.postApi(`driver/rating`,data2).subscribe(res=>{
      if(res.status == 201){
        api2 = true
        if(api1 && api2) {
          this.ordersList();
        }
      }
    },err=>{})
  }




  orderRating(item){
    this.selectedOrder = item
    $('#order-modal-so').modal({ backdrop: 'static', keyboard: false })
  }

  markStar(limit){
    this.restaurantStarRatingArr.map((item,index)=>{
      if(index <= limit) {    
        item.marked = true;   
      }else {
        item.marked = false
      }
    })
  }
  
  driverStar(limit){
    this.driverStarRatingArr.map((item,index) =>{
      if(index <= limit){
        item.marked = true;
      }else{
        item.marked = false;
      }
    })
   
  }


  initForm() {
    this.addressForm = new FormGroup({
      house: new FormControl(),
      area: new FormControl(),
      landmark: new FormControl()
    })
  }

  viewAddress() {
    this.spinner.show()
    this.service.getApi(`customer/address`).subscribe(res => {
      this.spinner.hide()
      console.log("------>",res);
      if (res.status == 200) { 
        this.addressDetailArr = res.body
      }
    }, err => {
      this.spinner.hide();
      if(err.status == 403){
        this.service.toastErr(err.error.detail)
      }
    })
  }

  viewProfile() {
    $('#Edit-profile').modal('hide')
    this.service.getApi('customer/view-edit-profile').subscribe((res: any) => {
      console.log('profile response', res)
      if (res.status == 200) {
        this.firstName = res.body.first_name
        this.phoneNo = res.body.mobile
        this.email = res.body.email
        this.countryCode = res.body.country_code
        console.log('info=-=-=-=>', this.firstName, this.phoneNo, this.email)
      }
    },err=>{
      this.spinner.hide()
      if(err.status == 403){
        this.service.toastErr(err.error.detail)
      }
    })
  }

 

  profileEdit() {
    $('#Edit-profile').modal({ backdrop: 'static', keyboard: false });
    this.closeEditModal();
  }


  changeNumber() {
    this.enterCountryCode = this.countryCode;
    this.loginForm.value.enterMobileNo = this.phoneNo
    this.showVerify=true
    this.showPass =false; 
    this.showOTP = false
    this.showEmail = false
  }


  verify() {
    //  -----API-----------HIT ----- SEND OTP ON NEW NUMBER-------------------------
    if (this.loginForm.value.enterMobileNo  == this.phoneNo) {
      this.service.showErrToast("New number and previous number can't be same");
      return;
      }

      if(!this.enterCountryCode) {
        this.service.showErrToast('Please enter country code!');
        return;
      }

      if (!this.phoneNo) {
        this.service.showErrToast("Pls enter mobile number!");
        return;
      }

      if(!this.enterCountryCode.includes('+')){
        this.enterCountryCode = '+' + this.enterCountryCode;
      }

      this.spinner.show()
       this.service.getApi(`merchant/otp-send`).subscribe(res => {
        this.spinner.hide()
       if(res.status == 200){
        this.service.showSuccToast(res.body.message)
        this.showVerify = false
        this.showOTP = true
        // this.phoneNo = this.loginForm.value.enterMobileNo
      }
     
    }, err => {
      this.spinner.hide()
      this.service.toastErr(err.error.message)
     })
  }

  //-----------------Confirm OTP ON Changing mobile number-------------------------------------//
  confirmOTP() {
    let data = {
      "code": this.enterOTP
    }

    this.service.postApi(`merchant/otp-verify`, data).subscribe(res => {
      if (res.status == 200) {
        this.service.showSuccToast(res.body.message);
        this.UpdateMobileNumberAPI()
      }

    }, err => {
      this.service.toastErr(err.error.message)
    })
  }

  UpdateMobileNumberAPI() {
    let data = {
      "country_code":this.enterCountryCode,
      "mobile":this.loginForm.value.enterMobileNo

    }
    this.spinner.show()
    this.service.putApi(`customer/view-edit-profile`, data).subscribe(res => {
      this.spinner.hide()
      if (res.status == 200) {
        this.service.showSuccess(res.body.message)
        this.showOTP = false;
        $('#phone-change').modal('hide')
        $('#Edit-profile').modal('hide')
        this.phoneNo = this.loginForm.value.enterMobileNo
        localStorage.setItem('resetNumber',"true"); 
        this.service.logOut()
       
       console.log("------>",String(window.location.href).includes('localhost'));
       if(String(window.location.href).includes('localhost')){
        window.location.href = 'http://localhost:4200'
       }
       else{
        window.location.href = 'https://fooddeliveryapp.mobiloitte.org/'
       }
       // window.location.href = 'http://localhost:4200';
      }

    }, err => {
      this.spinner.hide()
      if(err.status == 400 || err.status == 401){
        this.service.toastErr(err.error.message)
      }
      if(err.status == 400){
        this.showVerify = true
        this.showOTP = false
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
    })

  }

  otpConfirm() {
    var data = {
      "code": this.otpVerify.value.enterOtp
    }
    this.service.postApi('merchant/otp-verify', data).subscribe((res: any) => {
      if (res.status == 200) {
        this.updatedNumber()
      }
    })
  }

  updatedNumber() {
    var data = {
      // "first_name": "string",
      // "middle_name": "string",
      // "last_name": "string",
      "mobile": this.newNumber,
      // "email": "user@example.com",
      // "gender": "Male"
    }
    this.service.putApi('customer/view-edit-profile', data).subscribe((res) => {
      if (res.status == 200) {
        this.service.logOut()
      }
    })
  }

  editAddress(item) {
    // var data = {
    //   "house": "D-112",
    //   "location": this.service.location,
    //   "landmark": "pocket D",
    //   "address_type": "Other"
    // }
    // this.service.postApi(`customer/address`, data).subscribe((res : any) =>{
    //   if(res.status == 200){
    //     console.log(res)
    //   }
    // })
    
    if (!navigator.onLine) {
      this.service.toastErr('You are offline');
      return;
    }
    if (!this.addressForm.valid) {
      this.service.toastErr('Form not valid!');
    }
    this.viewDetailsObj = item;
    this.addressForm.patchValue({
      house: this.viewDetailsObj.house,
      area: this.viewDetailsObj.area,
      landmark: this.viewDetailsObj.landmark,
    })
  }

  saveAddress() {
    console.log(this.addressForm.value, this.viewDetailsObj.id);
    let data = {
      "house": this.addressForm.value.house,
      "location": this.viewDetailsObj.location,
      "landmark": this.addressForm.value.landmark,
      "address_type": this.viewDetailsObj.address_type,
      // "area":this.addressForm.value.area
    }
    this.spinner.show()
    this.service.putApi(`customer/address/${this.viewDetailsObj.id}`, data).subscribe(res => {
      this.spinner.hide()
      if (res.status == 200) {
        this.viewAddress()
        this.service.showSuccess(res.body.message)
      }
    }, err => {
      this.spinner.hide();
      this.service.toastErr(err.error.message)
    })
  }

  changeAddressType(type) {
    this.viewDetailsObj.address_type = type
  }

  deleteItem(item) {
    this.viewDetailsObj = item
  }
 

  deleteAddress() {
    if (!navigator.onLine) {
      this.service.toastErr('You are offline');
      return;
    }
    this.spinner.show()
    this.service.deleteApi(`customer/address/${this.viewDetailsObj.id}`).subscribe(res => {
      this.spinner.hide()
      if (res.status == 200) {
        let index = this.addressDetailArr.findIndex(item => item.id == this.viewDetailsObj.id);
        this.addressDetailArr.splice(index, 1)
        this.service.showSuccess(res.body.message)
      }
    }, err => {
      this.spinner.hide();
      this.service.toastErr(err.error.message)
    })
  }

  favouriteList() {
    if (!navigator.onLine) {
      this.service.toastErr('You are offline');
      return;
    }
    this.spinner.show()
    this.service.getApi(`customer/favorite-restaurant?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`).subscribe(res => {
      this.spinner.hide()
      if (res.status == 200) {
        this.favouriteListArr = res.body.restaurant;
        // this.outletId=res.body.restaurant[0].outlet.id
        console.log(this.favouriteListArr)
      }
    }, err => {
      this.spinner.hide()
      if (err.status == 400 || err.status == 401){
        this.service.toastErr(err.error.message)
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
    })

  }

  clickFavoriteRes(item) {
    this.favid = item.outlet.id
    console.log(this.favid)
    this.router.navigate(['/restaurant',  this.favid])
  }
  //----------------------------------------to update email ----------------------------------//
  changeEmail() {
    this.loginForm.value.enterNewEmail = this.email
    this.showEmail = true
    this.showPass =false; 
    this.showOTP = false
    this.showVerify = false
  }

  updateEmail() {
    let data = {
      "email": this.loginForm.value.enterNewEmail
    }
    this.spinner.show()
    this.service.putApi(`customer/view-edit-profile`, data).subscribe(res => {
      this.spinner.hide()
      if (res.status == 200) {
        this.service.showSuccess(res.body.message)
        location.reload();
        $('#Edit-profile').modal('hide')
        this.showEmail = false;
        this.closeEditModal();
      }
    }, err => {
      this.spinner.hide()
      if(err.status == 400 || err.ststus == 401){
        this.service.toastErr(err.error.message)
      }
      if(err.ststus == 400){
        this.showEmail = true
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
     })

  }


  //-----------------------------------------to update password------------------------------------//
  changePass(){
    this.showPass =true; 
    this.showOTP = false
    this.showEmail = false
  }

  updatePassword(){
    if(this.enterNewPass != this.enterConfirmPass ){
      this.service.toastErr("Password mis-matched");
      this.showPass = false
      return;
    }

    let data ={
      "password": this.loginForm.value.enterNewPass
    }
    this.service.putApi(`customer/view-edit-profile`,data).subscribe(res=>{
      if(res.status == 200){
        this.service.showSuccToast(res.body.message)
        this.closeEditModal();
        $('#Edit-profile').modal('hide');

      }
    },err=>{
      if(err.status == 400){
        this.service.toastErr(err.error.message)
      }else if(err.status == 403){
        this.service.toastErr(err.error.detail)
        this.router.navigate([''])
      }
    })

  //   let data = {
  //     "newPassword": this.enterNewPass,
  //     "confirmPass": this.enterConfirmPass,
  //   }
  //   this.service.putApi(`please-add-endpoint-here`, data).subscribe(res => {
  //     if (res.status == 200) {
  //       this.service.showSuccess("Update Successful Your Password has been update successfully")
  //       $('#Edit-profile').modal('hide');
  //       this.showPass=false
  //     }
  //   }, err => {
  //     this.service.toastErr(err.error.message)
  //    })
    
  }

  openCartRefreshModal(item){
    this.url =  "https://apifooddelivery.mobiloitte.com/"
    this.service.getApi(`customer/order-detail/${item.id}`).subscribe(res=>{
      if(res.status == 200){
      this.selectedOrder = res.body
      if(localStorage.getItem('myCart')){
        let mycart = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : [];
        if(mycart.length == 0) {
          this.addItemToCart(this.selectedOrder);
          return;
        }
        let index = mycart.findIndex(x=>x.restaurant_id == this.selectedOrder.id);
        if(index != -1) {
          this.addItemToCart(this.selectedOrder)
         }else {
           $('#CartRefresh').modal({ backdrop: 'static', keyboard: false })
       }
     }else {
       this.addItemToCart(this.selectedOrder)
     }
      }
    })
}

  startFreshYes(item) {
    localStorage.removeItem('myCart');
    this.addItemToCart(item)
  }

  addItemToCart(item) {
    let mycart = localStorage.getItem('myCart') ? JSON.parse(localStorage.getItem('myCart')) : [];
    let order_items = [...item.order_items];
    console.log(item.restaurant)
      order_items.forEach(x => {
        x['total_pay'] = x.item_price * x.quantity;
        x['restaurant_name'] = item.restaurant_name;
        x['restaurant_id'] = item.restaurant_id;
        x['outlet_id'] = item.restaurant_address.id;
        x['restaurant_image'] = item.restaurant_image ? item.restaurant_image : 'assets/images/r1.png';
      });
      order_items.map((x)=>{
      if(x.order_modifiers.length > 0) {
        x.order_modifiers.map((y)=>{
          y['selected']= true;
          x.total_pay = x.total_pay + y.selling_price
        });
        x.modifiers = true
      }else {
        x.modifiers = false
      }  
      if(x.order_options.length > 0) {
        x.order_options.map((y)=>{
          y['selected']= true;
          x.total_pay = x.total_pay + y.selling_price
        });
        x.options = true;
      }else {
        x.options = false
      }
      mycart.push(x)
      })
      let url = `customer/restaurant/${item.restaurant}/outlet/${item.restaurant_address.id}?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`
      this.service.getApi(url).subscribe((res: any) => {
        if (res.status == 200) {
          this.service.cartLength.next(String(mycart.length))
          localStorage.setItem('myCart',JSON.stringify(mycart));
          localStorage.setItem('restaurantDetail',JSON.stringify(res.body));
          localStorage.setItem('restaurantDetailId',JSON.stringify(res.body.id));
          this.service.showSuccess('Order added to Cart');
          this.router.navigate(['account1'])
        }
      },err=>{
        if(err.status == 200){
          this.service.toastErr(err.error.message)
        }
      })
  }

  initMap() {
  
    if (!navigator.onLine) {
      this.service.toastErr('You are offline!')
      return;
    }

    if (!navigator.geolocation) {
      this.service.toastErr('Geolocation is not supported by this browser.');
      return;
    }
    this.spinner.show()
    navigator.geolocation.getCurrentPosition(position => {
      this.lat = position.coords.latitude;
      this.lng = position.coords.longitude;
    })
  }


  handleLocationError (
    browserHasGeolocation: boolean,
    infoWindow: google.maps.InfoWindow,
    pos: google.maps.LatLng
  ) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    infoWindow.open(this.map);
  }
//--------------------------------To navigate Restaurant ------------------------------------//
  navigateToRestaurant(data,outletId) {
    console.log(data, outletId);
    
    let url = `customer/restaurant/${data}/outlet/${outletId}?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}`
    this.service.getApi(url).subscribe((res: any) => {      
      console.log("liksghfisug",res);
      
      if (res.status == 200) {
        localStorage.setItem('restaurantDetail',JSON.stringify(res.body));
        localStorage.setItem('restaurantDetailId',JSON.stringify(res.body.id));
        // this.router.navigate(['restaurant'])    
        this.router.navigate(['restaurant'])    
      }
    },err=>{
      if(err.status == 200){
        this.service.toastErr(err.error.message)
      }
    })
  }

  //-------------------save to payment card validation --------------------------------------//
  paymentCardFormValidation(){
    this.paymentCardForm = new FormGroup({
      // 'cardNumber': new FormControl('', Validators.compose([Validators.required])),
      'cardNumber': new FormControl('', Validators.compose([Validators.required, Validators.pattern(/^[1-16][0-16]{9,13}$/)])),

      "expiry": new FormControl('',Validators.required),
      'cvv': new FormControl('', Validators.compose([Validators.required])),
      "nameOncard": new FormControl('',Validators.required),

    })
  }


  applyDiscount(){
    this.cartArr.forEach(ele => {
      this.selectedItemsArray.push({ "id": ele.id, "price": ele.total_pay * ele.quantity })
    });

    let data ={
      "restaurant_id": Number(this.rest_id),
      "item_total": this.cartSubTotal,
      "items":  this.selectedItemsArray
          }
    console.log('applyCoupon', data)
     this.service.postApi('customer/apply-discount',data).subscribe((res: any) => {
      console.log('response on apply-discount',res);
      this.selectedItemsArray = []
     })
   

  }
  rest_id(rest_id: any) {
    throw new Error('Method not implemented.');
  }
  checkCart(){
    if(localStorage.getItem('myCart')){
      this.cartArr = JSON.parse(localStorage.getItem('myCart'));
      this.cartArr.forEach(item => {
        this.cartSubTotal = this.cartSubTotal + (item.total_pay * item.quantity)
      });
    }
  }

  // Get tax details api integration
  getTaxDetails() {
    this.service.getApi('api/tax').subscribe((res:any) => {
      if(res.status == 200){
        this.getTax = res.body.tax_percentage
      }
    })
}
  // Get getDeliveryCharges details api integration

getDeliveryCharges() {
  this.service.getApi('api/delivery-charges').subscribe((res:any) => {
    if(res.status == 200){
      this.getDeliveryCharge = res.body.charges
      console.log('this.getRestrantItemList', this.getDeliveryCharge)
    }
  })
}

closeEditModal() {
  this.showVerify = false
  this.showOTP = false
  this.showEmail = false
  this.showPass = false
}
//================ADD TO CARD =============================//
addCard(){
  let x = this.paymentCardForm.value.expiry.slice(0,2) - 1
  let y = 20 + this.paymentCardForm.value.expiry.slice(2,4) 
  let mydate = new Date(y , x)
  let data = 
    {
      "name":this.paymentCardForm.value.nameOncard,
      "number":this.paymentCardForm.value.cardNumber,
      "expire_date":this.datePipe.transform(mydate, 'yyyy-MM-dd')
    }
  this.service.showSpinner()
  this.service.postApi(`customer/card`,data).subscribe(res=>{
    this.service.hideSpinner()
    $('#add-new-cart').modal('hide')
   
    this.getCart()


  },err=>{
    this.service.hideSpinner()
    if(err.status >= 400){
      console.log(err)
      this.service.showErrToast(err.error.expire_date)
    }
  })
}
getCart(){
  this.service.showSpinner()
  this.service.getApi('customer/card').subscribe(res=>{
    this.service.hideSpinner()
    if(res.status == 200){
      console.log(res)
      this.getCartListArr = res.body
      this.cardId = res.body.id
      console.log(this.cardId)
    }
  },err=>{
    this.service.hideSpinner()
    this.service.showErrToast(err.error.message)
  })
}

deleteCard(){
  this.service.showSpinner()
  this.service.deleteApi(`customer/card/${this.cardId}`).subscribe(res=>{
    if(res.status == 204){
      this.service.showSuccToast("Card Deleted Successfully")
      this.getCart()
    }
  },err=>{
    this.service.hideSpinner()
    this.service.showErrToast(err.error.message)
  })
}
deletePayment(id){
  this.cardId = id
  $('#Alert-card').modal('show')
}



  }


