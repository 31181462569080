import { Component, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
declare var $:any;
@Component({
  selector: 'app-blockedrestaurants',
  templateUrl: './blockedrestaurants.component.html',
  styleUrls: ['./blockedrestaurants.component.css']
})
export class BlockedrestaurantsComponent implements OnInit {
  blockedRestaurant: any = [];
  blocked_id: any;
  selectedResIndex: any;

  constructor(private service: DataserviceService, private translate:TranslateService, public spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.getBlockedRestaurant()
  }

  getBlockedRestaurant() {
    this.service.getApi('customer/block').subscribe((res:any) => {
      console.log('get blocked list', res)
      if (res.status == 200) {
        this.blockedRestaurant = res.body.r_restaurant
        // this.service.showSuccess(res.body.message)
        console.log('this.blockedRestaurant', this.blockedRestaurant)
      }
    })
  }

  unblockRestaurant() {
    this.blockedRestaurant.forEach(ele => {
      this.blocked_id=ele.id
      console.log('elementID',ele.id)
    });
    var data = {
      "restaurant": [Number(this.blocked_id)]
  }
  this.service.putApi('customer/block',data).subscribe((res)=>{
    if(res.status == 200){
      this.blockedRestaurant.splice(this.selectedResIndex,1)
      $('#Alert').modal('hide')
      this.service.showSuccess(res.body.message)
    }
  })
  }


openAlertModal(index){
  this.selectedResIndex = index
  $('#Alert').modal({ backdrop: 'static', keyboard: false })
}
 
closeAlertModal(){
  $('#Alert').modal('hide')
}


}
