import { Component, OnInit } from '@angular/core';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nearyou',
  templateUrl: './nearyou.component.html',
  styleUrls: ['./nearyou.component.css']
})
export class NearyouComponent implements OnInit {
  lat: any;
  lang: any;
  restaurantList: any = [];
  length: any;
  pages: number = 1;
  page: number = 10;
  rest: any;
  geocoder: google.maps.Geocoder;
  lng: any;
  latlng: google.maps.LatLng;
  request: { location: any; };
  addressStatus: string;
  locationData: string;
  cusisinesList: any = [];
  filteredCuisineArr: any = [];
  totalItems: any=0;
  deliverystatus: any;
  limit: any=10;
  openSidenavFlag = false;
  pageSize:number=10
  itemsPerPage: number = 5
  pageNumber:number=1

  
  constructor(private service: DataserviceService,private activatedRoute : ActivatedRoute,
     private spinner: NgxSpinnerService, private router: Router,) {
      this.activatedRoute.queryParams.subscribe((res)=>{
        this.deliverystatus = res.value
        if(res.value == 'is_take_away'){
          this.getPickUpRestaurant()
        }
        else{
          this.getRestaurant();
        }
      })
  }

  ngOnInit() {
    window.scrollTo(0, 0);
  }



  // onResize(event) {
  //   let width;

  //   if(event != undefined){
  //     width = event.target.innerWidth;
  //   }
  //   else{
  //     width = document.body.clientWidth;
  //   }

  //   if (width >= 480) {
  //     this.openSidenavFlag = false;
  //   } 
  //   else {
  //     this.openSidenavFlag = true;
  //   }
  // }
  //----------------------------- To get pick up restaurant api integration -----------------//

  getPickUpRestaurant(){
    this.spinner.show();
    if (!navigator.onLine) {
      this.service.toastErr("You are offline");
      return;
    }
    this.restaurantList = []
    this.totalItems = 0
    this.service.getApi(`customer/restaurants?lat=${localStorage.getItem('latitude')}&long=${localStorage.getItem('longitude')}&is_take_away=true`).subscribe((res: any) => {
      if (res.status == 200) {
        this.totalItems = res.body.count
        this.restaurantList = res.body.results;
        this.cuisines()
        setTimeout(() => {
          
          this.spinner.hide();
        }, 2000);
      }
      else{
        this.spinner.hide();

      }
    }, error => {
      this.spinner.hide()
      if (error.status == 401 || error.status == 403) {
        this.service.toastErr(error.error.detail)
      }else if(error.status == 400){
        this.totalItems = 0;
        this.restaurantList = [];
      }
      })
  }

  // ------------------------------To get the restaurant list API------------------------------------
  getRestaurant() {
     
    if (!navigator.onLine) {
      this.service.toastErr("You are offline");
      return;
    }
    console.log(environment.getRestaurants);
    this.service.locationUpdate.subscribe((res)=>{
      console.log(' this.service.locationUpdate',res);
      if(Object.keys(res).length !=0){
        let latLang = JSON.parse(res)
        this.lat = latLang.lat;
        this.lng = latLang.lng;
        
      }
      else{
        this.lat = localStorage.getItem('latitude');
        this.lng = localStorage.getItem('longitude');
      }
  })
  this.spinner.show();
    this.restaurantList = []
    this.totalItems = 0
    let url = `customer/restaurants?lat=${this.lat}&long=${this.lng}&page=${this.pageNumber}`
    if(localStorage.getItem('deliveryType')) {
      let type = (localStorage.getItem('deliveryType')).trim()
      url = url + `&${type}`
    }
    this.service.getApi(url).subscribe(res => {
      if (res.status == 200) {
        this.totalItems = res.body.count
        this.restaurantList = res.body.results;
        this.cuisines()
        setTimeout(() => {
          
          this.spinner.hide();
        }, 2000);
      }
      else{
        this.spinner.hide();

      }
    }, error => {
      this.spinner.hide()
      if (error.status == 401) {
        this.service.toastErr(error.error.detail)
      }else if(error.status == 400){
        this.totalItems = 0;
        this.restaurantList = [];
      }else if(error.status == 403){
        this.service.toastErr(error.error.detail)
        this.router.navigate([''])
      }
      })
  }

   // ------------------------pagination -------------------------//
   pagination(page){
    this.pageNumber=page;
    this.getRestaurant()   
  }


  //-----------------------------------Api to search restaurants on basis of cuisines---------------------------------
  searchByFilter(type) {
    let url;
    if (type == 'cost_for_two') {
      url = environment.getRestaurants+`&cost_for_two=true`;
    } else if (type == 'delivery_time') {
      url = environment.getRestaurants+`&delivery_time=true`;
    } else if (type == 'rating') {
      url = environment.getRestaurants+`&rating=true`;
    }else if (type == 'distance'){
     url = environment.getRestaurants
    }else if (type == 'cuisine') {
      url = environment.getRestaurants+`&cuisines_id=${this.filteredCuisineArr}`;
    }

    if(localStorage.getItem('deliveryType')) {
      url = url + `&${(localStorage.getItem('deliveryType')).trim()}`
    }

    this.spinner.show()
    this.service.getApi(url).subscribe((res: any) => {
      this.spinner.hide()
      if (res.status == 200) {
        this.restaurantList = res.body.results;
        this.totalItems = res.body.count;
      }
    }, error => {
      this.spinner.hide()
      if (error.status == 401  || error.status == 400) {
        this.service.toastErr(error.error.detail || error.error.message)
      } else if (error.status == 400) {
        this.restaurantList = [];
        this.totalItems = 0;
      }else if(error.status == 403){
        this.service.toastErr(error.error.detail)
        this.router.navigate([''])
      }
    })

  }

  //-----------------------------------Api to get cuisines--------------------------------
  cuisines() {
    this.spinner.show()
    this.service.getApi(`api/cuisines-public`).subscribe((res: any) => {
      this.spinner.hide();
      if (res.status == 200) {
        this.cusisinesList = res.body;
        this.cusisinesList.forEach(element => {
          element['checkboxStatus'] = false
        });
      }
    }, err => {
      this.spinner.hide()
     })
  }

  //----------------------------------To navigate restaurant ------------------------------------//
  
  navigateToRestaurant(data) {
    localStorage.setItem('restaurantDetail',JSON.stringify(data));
    
        if(this.deliverystatus  == 'is_take_away'){
          this.router.navigate(['restaurant'],{queryParams : { value : this.deliverystatus}})
        }
        else{
          this.router.navigate(['restaurant'])
        }
  }


  //------------------------------------Manage check and uncheck cuisines-----------------------------
  addRemoveCusines(item) {
    item.checkboxStatus = !item.checkboxStatus;
    if (item.checkboxStatus) {
      this.filteredCuisineArr.push(item.id)  
    } else {
      let findIndex = this.filteredCuisineArr.findIndex(x => x == item.id)
      this.filteredCuisineArr.splice(findIndex, 1)
    }
  }

  resetCheckbox() {
    this.cusisinesList.forEach(element => {
      element['checkboxStatus'] = false
    });
    this.filteredCuisineArr = [];

  }

}

