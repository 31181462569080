import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataserviceService } from './Service/dataservice.service';

// ************************************* Module ****************************************************
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CookieService } from 'ngx-cookie-service';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import {NgxMaskModule} from 'ngx-mask-2'

// ************************************* Component *************************************************
import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';
import { HeaderFooterComponent } from './pages/header-footer/header-footer.component';
import { StaticcontentComponent } from './pages/staticcontent/staticcontent.component';
import { ViewnewsComponent } from './pages/viewnews/viewnews.component';
import { NearyouComponent } from './pages/nearyou/nearyou.component';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProfileComponent } from './pages/profile/profile.component';
import { BlockedrestaurantsComponent } from './pages/blockedrestaurants/blockedrestaurants.component';
import { SearchComponent } from './pages/search/search.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { Restaurant2Component } from './pages/restaurant2/restaurant2.component';
import { Account1Component } from './pages/account1/account1.component';
import { CartComponent } from './pages/cart/cart.component';
import { FooterComponent } from './pages/footer/footer.component';
import { Header1Component } from './pages/header1/header1.component';
import { Header2Component } from './pages/header2/header2.component';
import { SidemenuComponent } from './pages/sidemenu/sidemenu.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TrackOrderComponent } from './pages/track-order/track-order.component';
import { AgmDirectionModule } from 'agm-direction';
import { RideWithUsComponent } from './pages/ride-with-us/ride-with-us.component';
import{SignupRideUsComponent} from './pages/signup-ride-us/signup-ride-us.component';
import { DatePipe } from '@angular/common';



export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderFooterComponent,
    HelpComponent,
    StaticcontentComponent,
    ViewnewsComponent,
    NearyouComponent,
    RecommendedComponent,
    ThankyouComponent,
    ProfileComponent,
    BlockedrestaurantsComponent,
    SearchComponent,
    RestaurantComponent,
    Restaurant2Component,
    Account1Component,
    CartComponent,
    FooterComponent,
    Header1Component,
    Header2Component,
    SidemenuComponent,
    PageNotFoundComponent,
    TrackOrderComponent,
    RideWithUsComponent,
    SignupRideUsComponent,

  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GooglePlaceModule,
    AgmDirectionModule, 
    NgxMaskModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1,
      preventDuplicates: true,
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC-v9QfZ9vdJtGL9K3K3r5eDLKUwuQtTOM',
      libraries: ['places']
    }),
    NgxPageScrollModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [CookieService,Header1Component,NearyouComponent,
    // { provide: HTTP_INTERCEPTORS, useClass: DataserviceService, multi: true },

    // {provide: AuthServiceConfig, useFactory: provideConfig},
    DataserviceService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
