import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataserviceService } from 'src/app/Service/dataservice.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(public service:DataserviceService , private router : Router) { }

  ngOnInit() {
  }

  changeTab(path){
  this.router.navigate([path])
  this.service.hideSpinner();
}

}
