import { Component, OnInit } from '@angular/core';

  @Component({
    selector: 'app-ride-with-us',
    templateUrl: './ride-with-us.component.html',
    styleUrls: ['./ride-with-us.component.css']
  })
  export class RideWithUsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

  }
