import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { HelpComponent } from './pages/help/help.component';
import { StaticcontentComponent } from './pages/staticcontent/staticcontent.component';
import { ViewnewsComponent } from './pages/viewnews/viewnews.component';
import { NearyouComponent } from './pages/nearyou/nearyou.component';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { ThankyouComponent } from './pages/thankyou/thankyou.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { BlockedrestaurantsComponent } from './pages/blockedrestaurants/blockedrestaurants.component';
import { SearchComponent } from './pages/search/search.component';
import { RestaurantComponent } from './pages/restaurant/restaurant.component';
import { Restaurant2Component } from './pages/restaurant2/restaurant2.component';
import { Account1Component } from './pages/account1/account1.component';
import { CartComponent } from './pages/cart/cart.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { TrackOrderComponent } from './pages/track-order/track-order.component';
import { RideWithUsComponent } from './pages/ride-with-us/ride-with-us.component';
import { SignupRideUsComponent } from './pages/signup-ride-us/signup-ride-us.component';

const routes: Routes = [
  // {path:'',redirectTo:'home',pathMatch:'full'},
  { path: '', component: HomeComponent},
  { path: 'nearyou', component: NearyouComponent},
  { path: 'recommended', component: RecommendedComponent},
  { path: 'help', component: HelpComponent},
  { path: 'thankyou', component: ThankyouComponent},
  { path: 'profile', component: ProfileComponent},
  { path: 'blockedrestaurants', component: BlockedrestaurantsComponent},
  { path: 'search', component:SearchComponent},
  { path: 'restaurant', component: RestaurantComponent},
  { path: 'restaurant2/:id/:id', component: Restaurant2Component},
  { path: 'account1', component: Account1Component},
  { path: 'viewnews', component: ViewnewsComponent},
  { path: 'services/:type', component: StaticcontentComponent},
  { path: 'cart', component: CartComponent},
  { path: 'track-order', component: TrackOrderComponent},
  { path: 'ride-with-us', component: RideWithUsComponent},
  { path: 'signUp-ride', component: SignupRideUsComponent},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
