import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { DataserviceService } from 'src/app/Service/dataservice.service';
import { TravelMarker, TravelMarkerOptions, TravelData, TravelEvents, EventType } from 'travel-marker';

declare var $: any;
declare var google: any;


@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.css']
})
export class TrackOrderComponent implements OnInit {
  @ViewChild('placesRef', { static: true }) placesRef: GooglePlaceDirective;
  zoom: number = 16;
  orderDetailsObj : any;
  orderDetails: any;
  
  map: any;
  infoWindow: google.maps.InfoWindow;
  pos: { lat: number; lng: number; };
  
  
  // public lat = 24.799448;
  // public lng = 120.979021;
 
  restaurantStarRatingArr :any= [
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
  ];
  driverStarRatingArr:any = [
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
    {'marked': false},
  ];
  selectedOrder: any={};

  // initial center position for the map
  lat: number =0;
  lng: number =0;
 
  line: any;
  speedMultiplier = 0.05;
  marker: TravelMarker = null;
  originLat: any;
  originLong: any;
  destinationLat: any;
  destinationLong: any;
  locationData:any = [];
  
  constructor( public service:DataserviceService, public router:Router) {
   
    
  }
  


  ngOnInit() {
    let obj =
    {
      orderId : 28,
      restaurant_lat : 24.799448,
      restaurant_lng :  120.979021,
      address_lat : 24.799524,
      address_lng : 120.975017,
     order_status : 'Order Placed'
    }
    let trackOrder = localStorage.getItem('trackOrder') ? JSON.parse(localStorage.getItem('trackOrder')) : obj
    console.log(trackOrder.order_status)
    this.service.trackOrderObj['order_status'] = trackOrder.order_status
    console.log(this.service.trackOrderObj['order_status'])
    this.service.connectTrackOrderSocket(trackOrder.orderId);
    this.service.orderDetail()
    if(trackOrder.order_status == 'Order Picked' || trackOrder.order_status == 'Out for Delivery' || trackOrder.order_status == 'Delivered'){
      this.service.origin={lat:trackOrder.driver_lat,lng:trackOrder.driver_lng}//driver lat long;
      console.log('driver lat long',this.service.origin);
    }else{
      this.service.origin={lat:trackOrder.restaurant_lat,lng:trackOrder.restaurant_lng}//resta ;
      console.log('restaurant lat long',this.service.origin);
    }
    this.service.destination={lat:trackOrder.address_lat,lng:trackOrder.address_lng}//customer
    console.log('this.service.destination',this.service.destination);

    console.log(this.service.origin)
    console.log(this.service.destination)
    this.locationData=[
      [this.service.origin.lat, this.service.origin.lng],
      [this.service.destination.lat, this.service.destination.lng]
    ]
    this.lat=this.service.origin.lat
    this.lng=this.service.origin.lng
    console.log("Location data------>>>", this.locationData)
    if(trackOrder.order_status == 'Order Picked' || trackOrder.order_status == 'Out for Delivery' || trackOrder.order_status == 'Delivered'){
      this.play()
    }
    this.orderDetail();
    // this.service.getMockLocation().subscribe(res=>{
     
    //   this.locationData = res;
    //   console.log(this.locationData)
    //   this.mockDirections();
    // })
    // this.initMap();
   
   
   
    this.mockDirections();
  }


  orderDetail(){
    if(!localStorage.getItem('token')) {
      this.service.toastErr('Please login first!')
      this.router.navigate(['']);
      return;
    }
    let orderIdData = localStorage.getItem('trackOrder')? JSON.parse(localStorage.getItem('trackOrder'))  : '';
    let  orderId =orderIdData.orderId
    this.service.getApi(`customer/order-detail/${orderId}`).subscribe(res=>{
      if( res.status == 200){
        this.orderDetailsObj= res.body;
        this.orderDetailsObj['itemTotal'] =0
        this.orderDetailsObj.order_items.forEach(element => {
          console.log(element)
          element['sub_order'] = element.order_modifiers.map(item=>item.name).join()
          this.orderDetailsObj['itemTotal'] +=  element.item_price * element.quantity
          console.log(element.sub_order)
        });
        console.log(this.orderDetailsObj)
        this.originLat=this.orderDetailsObj.restaurant_address.latitude
        this.originLong=this.orderDetailsObj.restaurant_address.longitude
       
      }

    },err=>{})
  }


  markStar(limit){
    this.restaurantStarRatingArr.map((item,index)=>{
      if(index <= limit) {    
        item.marked = true;   
      }else {
        item.marked = false
      }
    })
  }
  driverStar(limit){
    this.driverStarRatingArr.map((item,index) =>{
      if(index <= limit){
        item.marked = true;
      }else{
        item.marked = false;
      }
    })
   
  }

  rateMark(){
    let count = 0;

    this.restaurantStarRatingArr.map(item=> {
      if(item.marked == true) {
        count = count + 1;
      }
    })
    let data = {
    "restaurant":this.orderDetailsObj.restaurant,
    "location":this.orderDetailsObj.restaurant_address.id,
    "order":this.orderDetailsObj.id,
    "rating": count
    }
    console.log('Restaurant Data', data)
    this.service.postApi(`customer/rating-restaurant`,data).subscribe(res =>{
      if(res.status == 200){
        console.log(res)
      }
    },err =>{})
    

    //--------------------------- driver rating API--------------------
    let count2 = 0;
    this.driverStarRatingArr.map(item=>{
      if(item.marked == true){
        count2 = count2 + 1;
      }
    })

     let data2 = {
    "driver":this.orderDetailsObj.driver_id,
	  "order":this.orderDetailsObj.id,
	  "rating":count2
    }
    this.service.postApi(`driver/rating`,data2).subscribe(res=>{
      if(res.status == 200){
        console.log(res)
      }
    },err=>{})
  }

  onMapReady(map: any) {
    console.log(map);
    this.map = map;
    // this.calcRoute();
    this.mockDirections();
    // this.initEvents();
  }

  //mock directions api
  mockDirections() {
    const locationArray = this.locationData.map(l => new google.maps.LatLng(l[0], l[1]));
    this.line = new google.maps.Polyline({
      strokeOpacity: 0.5,
      path: [],
      map: this.map
    });
    console.log(locationArray)
    locationArray.forEach(l => this.line.getPath().push(l));
    
    const start = new google.maps.LatLng(this.service.origin.lat, this.service.origin.lng); // here will be my origin coordinates
    const end = new google.maps.LatLng(this.service.destination.lat, this.service.destination.lng); // here will be my destination coordinates

    const startMarker = new google.maps.Marker({position: start, map: this.map,icon:'assets/images/food.png'});
    const endMarker = new google.maps.Marker({position: end, map: this.map,icon:'assets/images/home.jpg'});
    this.initRoute();
    
  }

   // initialize travel marker
   initRoute() {
    const route = this.line.getPath().getArray();
    console.log(route)

    // options
    const options: TravelMarkerOptions = {
      map: this.map,  // map object
      speed: 50,  // default 10 , animation speed
      interval: 10, // default 10, marker refresh time
      speedMultiplier: this.speedMultiplier,
      markerOptions: { 
        title: 'Travel Marker',
        animation: google.maps.Animation.DROP,
        icon: {
          url: 'https://i.imgur.com/eTYW75M.png',
          // This marker is 20 pixels wide by 32 pixels high.
          animation: google.maps.Animation.DROP,
          // size: new google.maps.Size(256, 256),
          scaledSize: new google.maps.Size(128,128),
          // The origin for this image is (0, 0).
          origin: new google.maps.Point(0, 0),
          // The anchor for this image is the base of the flagpole at (0, 32).
         // anchor: new google.maps.Point(53, 110)
        }
      },
    };
  
    // define marker
    this.marker = new TravelMarker(options);
    
    // add locations from direction service 
    this.marker.addLocation(route);
   // setTimeout(() => this.play(), 6000);
   
  }

  // play animation
  play() {
    this.marker.play();
  }

 


}